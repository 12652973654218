import { config } from "global/config";

export const routePaths = {
  // public routes

  auth: {
    logout: config.WEBAPP_HOST + "/dashboard/logout/",
  },
  accessDenied: "/access-denied",
  // private routes
  home: "/",

  insights: {
    boards: {
      base: {
        route: "/insights/boards",
      },
      report: "/insights/boards/b/:dashboardId/p/:propertyId/report",
    },
    properties: {
      base: {
        route: "/insights/properties",
      },
      add: "/insights/properties/add",
      edit: "/insights/properties/edit/:id",
    },
    views: {
      base: {
        route: "/insights/views",
      },
      detail: "/insights/views/:id/:sessionid/:playerid/:propertyId",
    },
  },

  //image
  image: {
    sources: {
      allsources: "/image/sources",
      addsource: "/image/sources/add",
      editsource: "/image/sources/:id",
      customdomain: "/image/soures/:id/domain",
      security: "/image/sources/:id/security",
      cachepolicy: "/image/sources/:id/cache",
      deletesource: "/image/sources/:id/action",
      verify: "/image/sources/:id/verify",
      setupinstruction: "/image/soures/:id/instructions",
    },
    setup: "/image/sources/:id/setup",
    gettingStarted: "/image/getting-started",
    analytics: "/image/analytics",
    cache: "/image/cache",

    reports: {
      allreports: "/image/reports",
      addreport: "/image/reports/add",
      editreport: "/image/reports/:id",
      deletereport: "/image/reports/:id/action",
    },
    alerts: {
      allalerts: "/image/alerts",
      addalert: "/image/alerts/add",
      editalert: "/image/alerts/:id",
    },
  },

  // video
  video: {
    analytics: "/video/analytics",
    topAssets: "/video/analytics/top-assets",
    sources: {
      allsources: "/video/collections",
      addsource: "/video/collections/add",
      editsource: "/video/collections/:id",
      embededsettings: "/video/collections/:id/embed-settings",
      channelsettings: "/video/collections/:id/channel-settings",
      videoprotection: "/video/collections/:id/video-protection",
      deletesource: "/video/collections/:id/action",
      webhook: "/video/collections/:id/storage-webhooks",
    },
    profiles: {
      allprofiles: "/video/profiles",
      addProfile: "/video/profiles/add",
      editprofile: "/video/profiles/:id",
      deleteprofile: "/video/profiles/:id/action",
    },
    alerts: {
      allalerts: "/video/alerts",
      addalert: "/video/alerts/add",
      editalert: "/video/alerts/:id",
      deletealert: "/video/alerts/:id/action",
    },
    reports: {
      allreports: "/video/reports",
      addreport: "/video/reports/add",
      editreport: "/video/reports/:id",
      deletereport: "/video/reports/:id/action",
    },
    cms: {
      manage: "/video/manage",
      manager: "/video/manager",
      assetDetails: "/video/manage/asset/:id",
      videoprocess: "/video/manage/process/:id",
      analytics: "/video/manage/asset/analytics/:id",
    },
    importers: {
      base: "/video/import-videos",
      add: "/video/import-videos/add",
    },
  },

  // live stream analytics
  stream:{
    sources: {
      allsources: "/stream/collections",
      addsource: "/stream/collections/add",
      editsource: "/stream/collections/:id",
    },
    assets: {
      base: "/stream/manage",
      add: "/stream/add",
      info: "/stream/manage/asset/:asset_id",
    }
  },

  //organization
  organization: {
    coupons: "/organization/coupons",
    webhooks: {
      base: "/organization/webhooks",
      add: "/organization/webhooks/add",
      edit: "/organization/webhooks/edit/:id",
    },
    accounts: {
      base: "/organization/accounts",
      add: "/organization/accounts/add",
      edit: "/organization/accounts/edit/:id",
    },
    drm: "/organization/drm-credentials",
    billing: {
      base: "/organization/billing",
      changebilling: "/organization/changebilling",
    },
  },

  help: "/help",
  //user
  user: {
    apikey: "/user/apikey",
    sessions: "/user/sessions",
    profile: "/user/profile",
  },

  started: "/getting-started",
  totalviews: "/total-views",
  unique: "/unique-users",
  playtime: "/playing-time",
  playback: "/completion-by-users",
  playbackCompleted: "/completion-by-views",
  concurrent: "/concurrent-users",
  playerStartup: "/player_startup_time",
  startupTime: "/startup_time",
  seek: "/seek_latency",
  rebufferPercentage: "/rebuffer_percentage",
  rebufferDuration: "/rebuffer_duration",
  rebufferCount: "/rebuffer_count",
  rebufferFreq: "/rebuffer_frequency",
  playbackExit: "/playback_exited",
  playbacFail: "/playback_failed",
  upscale: "/upscale_percentage",
  downscale: "/downscale_percentage",
  bitrate: "/average-bitrate",

  //PUBLIC PAGE ROUTES
  public: {
    player: "/page/:id",
  },
  review: "/g2-review",
};
