import React, { useState } from "react";
import toast from "react-hot-toast";
import Select from "react-select";
import { customSelectDropdownStyles } from "utils/constants";
import { customTrack } from "../../utils/constants";

export default function FeedbackModal({
  setFeedbackModal,
  theme,
  feedbackModal,
  userData,
}) {
  const [feedback, setFeedback] = useState("");
  const [feedbackText, setFeedbackText] = useState("");

  const feedbackQuestions = [
    {
      label: "Customer Support Responsiveness",
      value: "customer_support_responsiveness",
    },
    {
      label: "Reasonable Subscription Plans",
      value: "reasonable_subscription_plans",
    },
    {
      label: "My usage needed higher limits",
      value: "higher_limits",
    },
    {
      label: "Video Security Features",
      value: "video_security_features",
    },
    {
      label: "High Quality Video Streaming",
      value: "high_quality_video_streaming",
    },
    {
      label: "Easy-to-use dashboard",
      value: "easy_dashboard",
    },
    {
      label: "Image Optimization",
      value: "image_optimization",
    },
    {
      label: "Others (Please specify)",
      value: "others",
    },
  ];

  const handleSelectValue = (selectedOption) => {
    setFeedback(selectedOption);
    setFeedbackText("");
  };

  const sendFeedback = () => {
    if (feedback === "") {
      toast.error("Please select a feedback");
      return;
    }
    if (feedback.value === "others" && feedbackText === "") {
      toast.error("Please specify the feedback");
      return;
    }

    // Prepare feedback data to be tracked
    const feedbackData = {
      feedback: feedback.label,
      plan: feedbackModal.plan,
      type: feedbackModal.type,
      is_yearly: feedbackModal.is_yearly,
      trial: feedbackModal.trial,
      name: userData.name,
      email: userData.email,
    };

    // Add feedbackText if "others" option is selected and text is provided
    if (feedback.value === "others" && feedbackText !== "") {
      feedbackData.feedbackText = feedbackText;
    }

    customTrack("Post-upgrade survey filled", feedbackData);

    toast.success("Feedback shared successfully!");
    setFeedbackModal(false);
  };

  return (
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Plan Upgraded Successfully!</h4>
          <button
            className="btn btn-xs btn-icon btn-ghost-danger rounded-circle"
            onClick={() => setFeedbackModal(false)}
          >
            <i className="bi bi-x-lg" />
          </button>
        </div>
        <div className="modal-body">
          <p>We're Curious! What Inspired You to Upgrade?</p>
          <div className="row form-group">
            <div className="col-sm-12">
              <Select
                options={feedbackQuestions}
                placeholder="What made you decide to upgrade?"
                name="roles"
                styles={customSelectDropdownStyles(theme)}
                isSearchable={false}
                isClearable={false}
                onChange={handleSelectValue}
                value={feedback}
              />
            </div>
          </div>
          {feedback.value === "others" && (
            <div className="row form-group mt-2">
              <div className="col-sm-12">
                <input
                  className="form-control form-control"
                  placeholder="Please specify"
                  autoComplete="off"
                  name="others"
                  type="text"
                  value={feedbackText}
                  onChange={(e) => setFeedbackText(e.target.value)}
                  required
                />
              </div>
            </div>
          )}
        </div>
        <div className="modal-footer">
          <div className="d-flex align-items-center justify-content-center w-100">
            <button
              className="btn btn-sm btn-primary w-100"
              onClick={() => sendFeedback()}
            >
              <i className="bi bi-send" /> Send Feedback
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
