import React, { Fragment, useState } from "react";
import { videoPricing } from "utils/constants";
import PricingCards from "./PricingCard";
import Switch from "./Switch";
import { Modal } from "react-responsive-modal";
import DowngradeModal from "./DowngradeModal";

export default function Video({
  summary,
  stripe,
  onPlanSelection,
  orgData,
  toggle,
  videoFree,
  planType,
  handleYearlyPriceToggle,
  coupon,
  userData,
  onCloseModal,
}) {
  const [OpenDowngradeModal, setOpenDowngradeModal] = useState(false);

  const ConfirmDowngradePlan = () => {
    onPlanSelection(videoFree, "", planType, "downgrade", true);
    setOpenDowngradeModal(false);
  };

  return (
    <Fragment>
      {!summary ? (
        <div className="d-flex justify-content-between align-items-start">
          <div className="d-flex gap-2">
            <p className="text-white-60">{videoPricing.description}</p>
            {coupon?.value ? (
              <span className="text-primary">
                ({coupon?.value}
                {coupon?.discount_type === "percent" ? "%" : "$"} discount will
                be applied on the total price)
              </span>
            ) : (
              <></>
            )}
          </div>
          <div className="video_yearly_toggle">
            {/* // ## pricing modal swtich */}
            <Switch
              summary={summary}
              stripe={stripe}
              toggle={toggle}
              handleYearlyPriceToggle={handleYearlyPriceToggle}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      {/* ## VIDEO PRICING CARDS ## */}
      <div className="row">
        {videoPricing.plans.map((price, i) => {
          return (
            <PricingCards
              key={i}
              columnClass="col-lg-3"
              price={price}
              onPlanSelection={onPlanSelection}
              orgData={orgData}
              toggle={toggle}
              planType={planType}
              userData={userData}
              onCloseModal={onCloseModal}
            />
          );
        })}
        <div className="d-flex justify-content-between align-item-start">
          {!summary && !stripe && (
            <span className="text-muted">
              <a
                href="https://www.gumlet.com/pricing?tab=video"
                target="_blank"
                rel="noreferrer"
              >
                Learn more about our plans
                <i className="tio-arrow-forward ms-1" />
              </a>
            </span>
          )}
          <div>
            {orgData?.plan_data?.video.plan === "videoPlan1" ||
            orgData?.plan_data?.video.plan === "free" ? (
              <></>
            ) : (
              <div className="mt-2">
                Not using Gumlet, downgrade to{" "}
                <span
                  type="button"
                  className="text-primary ms-1 text-decoration-underline"
                  onClick={() => setOpenDowngradeModal(true)}
                >
                  Free Plan
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        open={OpenDowngradeModal}
        onClose={setOpenDowngradeModal}
        showCloseIcon={false}
      >
        <DowngradeModal
          setOpenDowngradeModal={setOpenDowngradeModal}
          ConfirmDowngradePlan={ConfirmDowngradePlan}
          videoFree={videoFree}
          planType={planType}
          orgData={orgData}
          onCloseModal={onCloseModal}
        />
      </Modal>
    </Fragment>
  );
}
