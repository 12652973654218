import moment from "moment";
import React, { Fragment } from "react";

export default function SummaryCard({
  finalSummaryData,
  finalFreeVideoSummaryData,
  finalImageSummaryData,
  confirmedPlan,
  toggle,
  getProratedPrice,
  getDiscountedProratedPrice,
  coupon,
}) {
  let today = moment();
  let futureDate = moment(today).add(14, "days");
  let formattedDate = futureDate.format("Do MMMM");

  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-6 mb-3 mb-sm-5">
          <h3 className="card-title">Selected Plan</h3>
          <br />
          <div className="table-responsive">
            {finalFreeVideoSummaryData && confirmedPlan?.type === "video" ? (
              <table className="table table-thead-bordered table-nowrap table-align-middle card-table">
                <tbody className="thead-light">
                  <tr>
                    <td className="fw-bold">Plan Name</td>
                    <td className="text-capitalize">
                      {finalFreeVideoSummaryData.plan}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-uppercase text-primary fs-6">
                      <b>Video</b>
                    </td>
                    <td></td>
                  </tr>
                  {finalFreeVideoSummaryData.data.map((item, i) => {
                    return (
                      <Fragment key={i}>
                        <tr>
                          <td>{item.label}</td>
                          <td>{item.value}</td>
                        </tr>
                      </Fragment>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <></>
            )}
            {finalSummaryData && confirmedPlan?.type === "video" ? (
              <table className="table table-thead-bordered table-nowrap table-align-middle card-table">
                <tbody className="thead-light">
                  <tr>
                    <td className="fw-bold">Plan Name</td>
                    <td className="text-capitalize">{finalSummaryData.plan}</td>
                  </tr>
                  <tr>
                    <td className="text-uppercase text-primary fs-6">
                      <b>Video</b>
                    </td>
                    <td></td>
                  </tr>
                  {finalSummaryData.data.map((item, i) => {
                    return (
                      <Fragment key={i}>
                        <tr>
                          <td>{item.label}</td>
                          <td>{item.value}</td>
                        </tr>
                      </Fragment>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <></>
            )}
            {finalImageSummaryData && confirmedPlan?.type === "image" ? (
              <table className="table table-thead-bordered table-nowrap table-align-middle card-table">
                <tbody className="thead-light">
                  <tr>
                    <td className="fw-bold">Plan Name</td>
                    <td className="text-capitalize">
                      {finalImageSummaryData?.plan}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-uppercase text-primary fs-6">
                      <b>Image</b>
                    </td>
                    <td></td>
                  </tr>
                  {finalImageSummaryData?.data.map((item, i) => {
                    return (
                      <Fragment key={i}>
                        <tr>
                          <td>{item.label}</td>
                          <td>{item.value}</td>
                        </tr>
                      </Fragment>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="col-sm-6 mb-3 mb-sm-5">
          <h3 className="card-title">Payment Summary</h3>
          <br />
          <div className="table-responsive">
            <table className="table table-thead-bordered table-nowrap table-align-middle card-table">
              <tbody>
                <tr>
                  <td>Plan Price </td>
                  <td>
                    {confirmedPlan.plans === "Free" ||
                    confirmedPlan.plans === "free"
                      ? "$0"
                      : confirmedPlan.discount + "/month"}
                  </td>
                </tr>

                {confirmedPlan.plans !== "Free" && !toggle && (
                  <tr>
                    <td>Prorated Cost</td>
                    <td>
                      {getProratedPrice(
                        confirmedPlan.price,
                        confirmedPlan.is_yearly
                      )}
                    </td>
                  </tr>
                )}
                <tr>
                  <td className="border-bottom-0 pb-1">
                    {" "}
                    <div className="d-flex flex-column gap-1">
                      <b>Total Due Today</b>
                    </div>
                  </td>
                  <td className="border-bottom-0 pb-1">
                    {" "}
                    <div>
                      <b>
                        {confirmedPlan.plans === "Free" ||
                        confirmedPlan.plans === "free" ||
                        confirmedPlan.trial
                          ? "$0"
                          : getDiscountedProratedPrice(
                              confirmedPlan.price,
                              confirmedPlan.yearly,
                              confirmedPlan.is_yearly
                            )}
                      </b>
                    </div>
                  </td>
                </tr>
                <td colSpan="2">
                  {coupon?.value ? (
                    <p className="text-primary fs-6 pt-0">
                      ({coupon?.value}
                      {coupon?.discount_type === "percent" ? "%" : "$"} discount
                      will be applied on the total price)
                    </p>
                  ) : (
                    <></>
                  )}
                </td>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row justify-content-start">
        <div className="col-lg-12">
          <p>
            {confirmedPlan.trial
              ? `You will charged ${getDiscountedProratedPrice(
                  confirmedPlan.price,
                  confirmedPlan.yearly,
                  confirmedPlan.is_yearly
                )} after your trial ends on ${formattedDate}.`
              : "If you're unhappy with your purchase for any reason, we provide 30 day no questions asked refunds."}
          </p>
        </div>
      </div>
    </Fragment>
  );
}
