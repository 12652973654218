import { routePaths } from "global/routePaths";
import { formatDuration } from "utils/helper";
import { formatDistanceStrict } from "date-fns";

// Tagify basic settings
export const baseSetting = {
  placeholder: "e.g Video tag ( ↵ Enter ) ",
  pattern: /^[a-zA-Z0-9-_]+$/,
};

export const customTrack = (trackerString, trackingObject) => {
  if (typeof window.gumletSegment !== "undefined") {
    window.gumletSegment.track(trackerString, trackingObject);
  } else {
    console.log(
      "window.gumletSegment is not defined. Tracking cannot be performed."
    );
  }
};

export const READ_ONLY_TEXT =
  "You have read only access. Please contact your administrator.";
export const VIDEO_PROCESS =
  "Please wait for the video to be processed to access this.";

export const stripeCardStyle = (theme) => {
  return {
    theme: theme == "dark" ? "night" : "stripe",
    variables: {
      colorPrimary: "#6759eb",
      fontSizeBase: "14px",
      fontFamily: "Inter, system-ui, sans-serif",
    },
  };
};

export const METRICS = {
  playback: {
    path: routePaths.insights.views.base.route,
    title: "",
    tag: "playbacks",
    desc: "",
  },
  views: {
    path: routePaths.totalviews,
    title: "Total Views",
    tag: "views",
    func: "sum",
    desc: "The total number of views that started during the selected time interval.",
    unit: "Views",
  },
  unique_views: {
    path: routePaths.unique,
    title: "Unique Users",
    tag: "unique_views",
    func: "sum",
    desc: "Total number of unique viewers in given time frame.",
    unit: "Users",
  },
  playing_time: {
    path: routePaths.playtime,
    title: "Playing Time",
    func: "sum",
    tag: "playing_time",
    desc: "The total time in hours where viewers watched the video. This time excludes seek time, rebuffering time and the time users spent while the video was paused.",
    unit: "Hours",
  },
  completion_percent: {
    path: routePaths.playback,
    title: "Playback Completed By Users",
    func: "average",
    tag: "completion_percent",
    desc: "Distribution of viewers based on the completion of playback before the viewer dropped off.",
    unit: "Percentage Of Users",
  },
  completion_percent_by_views: {
    path: routePaths.playbackCompleted,
    title: "Playback Completed By Views",
    func: "average",
    tag: "completion_percent_by_views",
    desc: "Distribution of viewers based on the completion of playback before the viewer dropped off.",
    unit: "Percentage Of Views",
  },
  concurrent_users: {
    path: routePaths.concurrent,
    title: "Max Concurrent Users",
    func: "average",
    tag: "concurrent_users",
    desc: "Maximum number of user playing any playback at the same time",
    unit: "Max Concurrent Users",
  },
  player_startup_time: {
    path: routePaths.playerStartup,
    title: "Player Startup Time",
    func: "average",
    tag: "player_startup_time",
    desc: "Time the player takes to initialise itself and is ready for further interaction with the viewer to be able to start the playback.",
    unit: "Ms",
  },
  startup_time: {
    path: routePaths.startupTime,
    title: "Video Startup Time",
    func: "average",
    tag: "startup_time",
    desc: "Time the player takes when the viewer clicks the play button for the first time to the time first frame of the video is shown to the viewer. In case of auto play this time is taken from the time when the player is instructed to play the video till the time the first frame is shown to the user.",
    unit: "Video Startup Time (ms)",
  },
  seek_latency: {
    path: routePaths.seek,
    title: "Seek Latency",
    func: "average",
    tag: "seek_latency",
    desc: "Total amount of time spent waiting for playback to resume after the viewer seeks to a new location.",
    unit: "Seek Latency (ms)",
  },
  rebuffer_percentage: {
    path: routePaths.rebufferPercentage,
    title: "Rebuffer Percentage",
    func: "average",
    tag: "rebuffer_percentage",
    desc: "Measure of volume of rebuffering occurring across all video playbacks. Rebuffer Percentage = buffering time * 100 / total view time",
    unit: "Rebuffer Percentage (%)",
  },
  rebuffer_duration: {
    path: routePaths.rebufferDuration,
    title: "Rebuffer Duration",
    func: "average",
    tag: "rebuffer_duration",
    desc: "Total amount of time viewers spent waiting for the video to buffer.",
    unit: "Ms",
  },
  rebuffer_count: {
    path: routePaths.rebufferCount,
    title: "Rebuffer Count",
    func: "sum",
    tag: "rebuffer_count",
    desc: "Number of rebuffering events that happen during the video view.",
    unit: "Rebuffers",
  },
  rebuffer_frequency: {
    path: routePaths.rebufferFreq,
    title: "Rebuffer Frequency",
    func: "average",
    tag: "rebuffer_frequency",
    desc: "Measures how often rebuffering events happen.",
    unit: "Rebuffers Per Minute",
  },
  exits_before_startup: {
    path: routePaths.playbackExit,
    title: "Playback Rate",
    func: "average",
    tag: "playback_rate",
    desc: "This metric identifies the average number of viewers who started watching the playback.",
    unit: "Playback Rate (%)",
  },
  playback_failure_percentage: {
    path: routePaths.playbacFail,
    title: "Playback Failed",
    func: "average",
    tag: "playback_failure_percentage",
    desc: "Percentage of viewers who experienced an error at any point during the playback.",
    unit: "Playback Failed (%)",
  },
  upscale_percentage: {
    path: routePaths.upscale,
    title: "Upscaling",
    func: "average",
    tag: "upscale_percentage",
    desc: "Maximum Upscale Percentage at any point in time during a video view.",
    unit: "Upscaling Percentage (%)",
  },
  downscale_percentage: {
    path: routePaths.downscale,
    title: "Bandwidth Wastage",
    func: "average",
    tag: "downscale_percentage",
    desc: "Maximum Downscale Percentage at any point in time during a video view",
    unit: "Downscale Percentage (%)",
  },
  average_bitrate: {
    path: routePaths.bitrate,
    title: "Average Bitrate",
    func: "average",
    tag: "average_bitrate",
    desc: "The average amount of data bring transferred for all playbacks in the selected timeframe.",
    unit: "Mbps",
  },
};

export const DATETIMEFORMAT = "DD MMM YYYY hh:mm A";

export const showFilter = {
  ios: "iOS",
  Ios: "iOS",
  IOS: "iOS",
  iOS: "iOS",
  ipad: "iPad",
  iPad: "iPad",
  iPhone: "iPhone",
  iphone: "iPhone",
  Avplayer: "AVPlayer",
  avplayer: "AVPlayer",
  AVPlayer: "AVPlayer",
};

export const SOURCE_TYPE_DATA = {
  webfolder: [
    {
      name: "Base URL",
      type: "input",
      description:
        "Please enter the domain name from where your images are served. For example, if your image URLs look like <code>https://example.com/folder1/images/uploads/sample.png. </code>Just enter <code>https://example.com</code>",
      tool_tip: "",
      placeholder: "e.g https://example.com",
      api_key: "base_url",
    },
    {
      name: "Gumlet Subdomain Name",
      type: "inputWithAppend",
      description:
        "This is the subdomain which will be used to serve your images. For example, if you enter<code>&nbsp;sample </code> here, all your images will be accessible from<code>&nbsp;sample.gumlet.io</code> domain name.",
      tool_tip: "subdomain cannot be changed once the source created",
      placeholder: "e.g sample",
      api_key: "namespace",
    },
  ],
  proxy: [
    {
      name: "Restricted Domain",
      type: "input",
      description:
        "Images will be delivered only when requests are coming from above listed domains. We use&nbsp;<code>referer</code> header of incoming requests to decide if we should serve image. Please enter comma separated list of domains.",
      tool_tip: "",
      placeholder: "Comma separated domain names (optional)",
      api_key: "whitelisted_domains",
    },
    {
      name: "Gumlet Subdomain Name",
      type: "inputWithAppend",
      description:
        "This is the subdomain which will be used to serve your images. For example, if you enter<code>&nbsp;sample </code> here, all your images will be accessible from<code>&nbsp;sample.gumlet.io</code> domain name.",
      tool_tip: "subdomain cannot be changed once the source created",
      placeholder: "e.g sample",
      api_key: "namespace",
    },
  ],
  aws: [
    {
      name: "Gumlet Subdomain Name",
      type: "inputWithAppend",
      description:
        "This is the subdomain which will be used to serve your images. For example, if you enter<code>&nbsp;sample </code> here, all your images will be accessible from<code>&nbsp;sample.gumlet.io</code> domain name.",
      tool_tip: "subdomain cannot be changed once the source created",
      placeholder: "e.g sample",
      api_key: "namespace",
    },
    {
      name: "Bucket Name",
      type: "input",
      description: "",
      tool_tip: "",
      placeholder: "Bucket Name",
      api_key: "bucket_name",
    },
    {
      name: "Bucket Region",
      type: "select",
      description: "",
      tool_tip: "",
      placeholder: "Select Region",
      level: "Second",
      api_key: "bucket_region",
      options: [
        {
          label: "Asia Pacific (Mumbai) ap-south-1",
          value: "ap-south-1",
        },
        {
          label: "Asia Pacific (Seoul) ap-northeast-2",
          value: "ap-northeast-2",
        },
        {
          label: "Asia Pacific (Singapore) ap-southeast-1",
          value: "ap-southeast-1",
        },
        {
          label: "Asia Pacific (Sydney) ap-southeast-2",
          value: "ap-southeast-2",
        },
        {
          label: "Asia Pacific (Tokyo) ap-northeast-1",
          value: "ap-northeast-1",
        },
        {
          label: "Asia Pacific (Osaka) ap-northeast-3",
          value: "ap-northeast-3",
        },
        {
          label: "Canada (Central) ca-central-1",
          value: "ca-central-1",
        },
        {
          label: "EU (Frankfurt) eu-central-1",
          value: "eu-central-1",
        },
        {
          label: "EU (Ireland) eu-west-1",
          value: "eu-west-1",
        },
        {
          label: "EU (London) eu-west-2",
          value: "eu-west-2",
        },
        {
          label: "EU (Paris) eu-west-3",
          value: "eu-west-3",
        },
        {
          label: "EU (StockHolm) eu-north-1",
          value: "eu-north-1",
        },
        {
          label: "South America (São Paulo) sa-east-1",
          value: "sa-east-1",
        },
        {
          label: "US East (N. Virginia) us-east-1",
          value: "us-east-1",
        },
        {
          label: "US East (Ohio) us-east-2",
          value: "us-east-2",
        },
        {
          label: "US West (N. California) us-west-1",
          value: "us-west-1",
        },
        {
          label: "US West (Oregon) us-west-2",
          value: "us-west-2",
        },
      ],
    },
    {
      name: "Base Path",
      type: "input",
      description: "",
      tool_tip:
        "An optional path that is prepended to image path when building request URL.",
      placeholder: "e.g. /images/some_path",
      api_key: "base_path",
    },
    {
      name: "Access Key",
      type: "input",
      description: "",
      tool_tip: "",
      placeholder: "Access Key",
      api_key: "access_key",
    },
    {
      name: "Secret",
      type: "input",
      description:
        "( We suggest you provide a read-only access for your bucket.)",
      tool_tip: "",
      placeholder: "Access Secret",
      api_key: "secret",
    },
  ],
  dostorage: [
    {
      name: "Gumlet Subdomain Name",
      type: "inputWithAppend",
      description:
        "This is the subdomain which will be used to serve your images. For example, if you enter<code>&nbsp;sample </code> here, all your images will be accessible from<code>&nbsp;sample.gumlet.io</code> domain name.",
      tool_tip: "subdomain cannot be changed once the source created",
      placeholder: "e.g sample",
      api_key: "namespace",
    },
    {
      name: "Bucket Name",
      type: "input",
      description: "",
      tool_tip: "",
      placeholder: "Bucket Name",
      api_key: "bucket_name",
    },
    {
      name: "Spaces Region",
      type: "select",
      description: "",
      tool_tip: "",
      placeholder: "Select Region",
      level: "Second",
      api_key: "bucket_region",
      options: [
        {
          label: "New York (nyc3)",
          value: "nyc3",
        },
        {
          label: "Amsterdam York (ams3)",
          value: "ams3",
        },
        {
          label: "Singapore (sgp1)",
          value: "sgp1",
        },
        {
          label: "Bengaluru (blr1)",
          value: "blr1",
        },
        {
          label: "San Francisco York (sfo2)",
          value: "sfo2",
        },
        {
          label: "Frankfurt (fra1)",
          value: "fra1",
        },
      ],
    },
    {
      name: "Base Path",
      type: "input",
      description: "",
      tool_tip:
        "An optional path that is prepended to image path when building request URL.",
      placeholder: "e.g. /images/some_path",
      api_key: "base_path",
    },
    {
      name: "Access Key",
      type: "input",
      description: "",
      tool_tip: "",
      placeholder: "Access Key",
      api_key: "access_key",
    },
    {
      name: "Secret",
      type: "input",
      description:
        "( We suggest you provide a read-only access for your bucket.)",
      tool_tip: "",
      placeholder: "Access Secret",
      api_key: "secret",
    },
  ],
  wasabi: [
    {
      name: "Gumlet Subdomain Name",
      type: "inputWithAppend",
      description:
        "This is the subdomain which will be used to serve your images. For example, if you enter<code>&nbsp;sample </code> here, all your images will be accessible from<code>&nbsp;sample.gumlet.io</code> domain name.",
      tool_tip: "subdomain cannot be changed once the source created",
      placeholder: "e.g sample",
      api_key: "namespace",
    },
    {
      name: "Bucket Name",
      type: "input",
      description: "",
      tool_tip: "",
      placeholder: "Bucket Name",
      api_key: "bucket_name",
    },
    {
      name: "Wasabi Region",
      type: "select",
      description: "",
      tool_tip: "",
      placeholder: "Select Region",
      level: "Second",
      api_key: "bucket_region",
      options: [
        {
          label: "Oregon (us-west-1)",
          value: "us-west-1",
        },
        {
          label: "North Virginia (us-east-1)",
          value: "us-east-1",
        },
        {
          label: "North Virginia (us-east-2)",
          value: "us-east-2",
        },
        {
          label: "Amsterdam (eu-central-1)",
          value: "eu-central-1",
        },
      ],
    },
    {
      name: "Base Path",
      type: "input",
      description: "",
      tool_tip:
        "An optional path that is prepended to image path when building request URL.",
      placeholder: "e.g. /images/some_path",
      api_key: "base_path",
    },
    {
      name: "Access Key",
      type: "input",
      description: "",
      tool_tip: "",
      placeholder: "Access Key",
      api_key: "access_key",
    },
    {
      name: "Secret",
      type: "input",
      description:
        "( We suggest you provide a read-only access for your bucket.)",
      tool_tip: "",
      placeholder: "Access Secret",
      api_key: "secret",
    },
  ],
  gcs: [
    {
      name: "Gumlet Subdomain Name",
      type: "inputWithAppend",
      description:
        "This is the subdomain which will be used to serve your images. For example, if you enter<code>&nbsp;sample </code> here, all your images will be accessible from<code>&nbsp;sample.gumlet.io</code> domain name.",
      tool_tip: "subdomain cannot be changed once the source created",
      placeholder: "e.g sample",
      api_key: "namespace",
    },
    {
      name: "Bucket Name",
      type: "input",
      description: "",
      tool_tip: "",
      placeholder: "Bucket Name",
      api_key: "bucket_name",
    },
    {
      name: "GCS Service Account JSON Key (how to generate?)",
      type: "textfield",
      description:
        "(You need to provide 'Storage Object Viewer' role to a service account and provide us JSON key for the same.)",
      tool_tip: "",
      placeholder: "GCS Service Account JSON Key",
      api_key: "service_account_key",
    },
  ],
  azure: [
    {
      name: "Gumlet Subdomain Name",
      type: "inputWithAppend",
      description:
        "This is the subdomain which will be used to serve your images. For example, if you enter<code>&nbsp;sample </code> here, all your images will be accessible from<code>&nbsp;sample.gumlet.io</code> domain name.",
      tool_tip: "subdomain cannot be changed once the source created",
      placeholder: "e.g sample",
      api_key: "namespace",
    },
    {
      name: "Azure Blob Name",
      type: "input",
      description: "",
      tool_tip: "",
      placeholder: "Azure blob Name",
      api_key: "azure_account_name",
    },
    {
      name: "Azure Container Name",
      type: "input",
      description: "",
      tool_tip: "",
      placeholder: "Container Name",
      api_key: "azure_container_name",
    },
    {
      name: "Azure Shared Name",
      type: "input",
      description: "",
      tool_tip: "",
      placeholder: "Shared Token",
      api_key: "azure_shared_token",
    },
    {
      name: "Azure Base Path",
      type: "input",
      description: "",
      tool_tip: "",
      placeholder: "Base Path",
      api_key: "azure_path",
    },
  ],
  linode: [
    {
      name: "Gumlet Subdomain Name",
      type: "inputWithAppend",
      description:
        "This is the subdomain which will be used to serve your images. For example, if you enter<code>&nbsp;sample </code> here, all your images will be accessible from<code>&nbsp;sample.gumlet.io</code> domain name.",
      tool_tip: "subdomain cannot be changed once the source created",
      placeholder: "e.g sample",
      api_key: "namespace",
    },
    {
      name: "Bucket Name",
      type: "input",
      description: "",
      tool_tip: "",
      placeholder: "Bucket Name",
      api_key: "bucket_name",
    },
    {
      name: "Linode Region",
      type: "select",
      description: "",
      tool_tip: "",
      placeholder: "Select Region",
      level: "Second",
      api_key: "bucket_region",
      options: [
        {
          label: "Singapore, SG",
          value: "ap-south-1",
        },
        {
          label: "Newark, NJ",
          value: "us-east-1",
        },
        {
          label: "Atlanta, GA",
          value: "us-southeast-1",
        },
        {
          label: "Frankfurt, DE",
          value: "eu-central-1",
        },
      ],
    },
    {
      name: "Access Key",
      type: "input",
      description: "",
      tool_tip: "",
      placeholder: "Access Key",
      api_key: "access_key",
    },
    {
      name: "Secret",
      type: "input",
      description:
        "( We suggest you provide a read-only access for your bucket.)",
      tool_tip: "",
      placeholder: "Access Secret",
      api_key: "secret",
    },
  ],
  backblaze: [
    {
      name: "Gumlet Subdomain Name",
      type: "inputWithAppend",
      description:
        "This is the subdomain which will be used to serve your images. For example, if you enter<code>&nbsp;sample </code> here, all your images will be accessible from<code>&nbsp;sample.gumlet.io</code> domain name.",
      tool_tip: "subdomain cannot be changed once the source created",
      placeholder: "e.g sample",
      api_key: "namespace",
    },
    {
      name: "Bucket Name",
      type: "input",
      description: "",
      tool_tip: "",
      placeholder: "Bucket Name",
      api_key: "bucket_name",
    },
    {
      name: "Backblaze Region",
      type: "select",
      description: "",
      tool_tip: "",
      placeholder: "Select Region",
      level: "Second",
      api_key: "bucket_region",
      options: [
        {
          label: "us-west-001",
          value: "us-west-001",
        },
        {
          label: "us-west-002",
          value: "us-west-002",
        },
        {
          label: "us-west-003",
          value: "us-west-003",
        },
        {
          label: "us-west-004",
          value: "us-west-004",
        },
        {
          label: "us-west-005",
          value: "us-west-005",
        },
      ],
    },
    {
      name: "Base Path",
      type: "input",
      description: "",
      tool_tip:
        "An optional path that is prepended to image path when building request URL.",
      placeholder: "e.g. /images/some_path",
      api_key: "base_path",
    },
    {
      name: "Access Key",
      type: "input",
      description: "",
      tool_tip: "",
      placeholder: "Access Key",
      api_key: "access_key",
    },
    {
      name: "Secret",
      type: "input",
      description:
        "( We suggest you provide a read-only access for your bucket.)",
      tool_tip: "",
      placeholder: "Access Secret",
      api_key: "secret",
    },
  ],
};

export const SOURCE_DATA = {
  wordpress: {
    image: "https://assets.gumlet.io/public/img/wordpress-logo-dashboard.png",
    maxHeight: "3rem",
    maxWidth: "",
    title: "Wordpress",
    description: "For WordPress and Woocommerce sites",
    url_key: "wordpress",
    fields: [
      {
        name: "Website URL",
        type: "input",
        api_key: "website_url",
        description:
          "This should be the domain name or hostname from where your images are loading. If your website is <code>https://www.example.com </code> , please enter that here.",
        tool_tip: "",
        placeholder: "e.g https://example.com",
      },
      {
        name: "Gumlet Subdomain Name",
        type: "inputWithAppend",
        api_key: "namespace",
        description:
          "This is the subdomain which will be used to serve your images. For example, if you enter<code>&nbsp;sample </code> here, all your images will be accessible from<code>&nbsp;sample.gumlet.io</code> domain name.",
        tool_tip: "subdomain cannot be changed once the source created",
        placeholder: "e.g sample",
      },
    ],
  },
  web: {
    image: "https://www.gumlet.com/public/dashboard/img/folder.png",
    maxHeight: "",
    maxWidth: "3rem",
    title: "Web Folder",
    description: "For all kinds of sites and apps",
    url_key: "web",
    defaultSelected: {
      label: "Web Folder",
      value: "webfolder",
    },
    fields: [
      {
        name: "Source type",
        type: "select",
        placeholder: "Select",
        api_key: "option",
        options: [
          {
            label: "Web Folder",
            value: "webfolder",
          },
          {
            label: "Web Proxy",
            value: "proxy",
          },
        ],
        description:
          "Source type gives us information about which storage system you use for your video storage. We fetch master videos from the source you configure here.",
        tool_tip: "https://docs.gumlet.com/docs/configure-image-source",
      },
    ],
  },
  cloud: {
    image: "https://www.gumlet.com/public/dashboard/img/cloud.png",
    maxHeight: "",
    maxWidth: "2.5rem",
    title: "Cloud Storage",
    description:
      "AWS S3, DO spaces, GCP Storage, Wasabi, Azure, Linode, Backblaze.",
    url_key: "cloud",
    defaultSelected: {
      label: "Amazon S3 Bucket",
      value: "aws",
    },
    fields: [
      {
        name: "Source type",
        type: "select",
        placeholder: "Select",
        api_key: "option",
        options: [
          {
            label: "Amazon S3 Bucket",
            value: "aws",
          },
          {
            label: "DigitalOcean Spaces Bucket",
            value: "dostorage",
          },
          {
            label: "Wasabi Storage",
            value: "wasabi",
          },
          {
            label: "Google Cloud Storage",
            value: "gcs",
          },
          {
            label: "Azure Storage",
            value: "azure",
          },
          {
            label: "Linode Storage",
            value: "linode",
          },
          {
            label: "Backblaze Storage",
            value: "backblaze",
          },
        ],
        description:
          "Source type gives us information about which storage system you use for your video storage. We fetch master videos from the source you configure here.",
        tool_tip: "https://docs.gumlet.com/docs/configure-image-source",
      },
    ],
  },
  cloudinary: {
    image: "https://www.gumlet.com/public/dashboard/img/cloudinary.png",
    maxHeight: "",
    maxWidth: "3.3rem",
    title: "Cloudinary",
    description: "To Migrate from Cloudinary",
    url_key: "cloudinary",
    fields: [
      {
        name: "Host Name",
        type: "input",
        description: "",
        tool_tip: "",
        placeholder: "e.g cloudinary",
        api_key: "host_name",
      },
      {
        name: "Cloud Name",
        type: "input",
        description: "",
        tool_tip: "",
        placeholder: "e.g Sample",
        api_key: "cloud_name",
      },
      {
        name: "Gumlet Subdomain Name",
        type: "inputWithAppend",
        description:
          "This is the subdomain which will be used to serve your images. For example, if you enter<code>&nbsp;sample </code> here, all your images will be accessible from<code>&nbsp;sample.gumlet.io</code> domain name.",
        tool_tip: "subdomain cannot be changed once the source created",
        placeholder: "e.g sample",
        api_key: "namespace",
      },
    ],
  },
  imgix: {
    image: "https://www.gumlet.com/public/dashboard/img/imgix.svg",
    maxHeight: "3.5rem",
    maxWidth: "",
    title: "Imgix",
    description: "To Migrate from Imgix",
    url_key: "imgix",
    defaultSelected: {
      label: "Web Folder",
      value: "webfolder",
    },
    fields: [
      {
        name: "Source type",
        type: "select",
        placeholder: "Select",
        api_key: "option",
        options: [
          {
            label: "Web Folder",
            value: "webfolder",
          },
          {
            label: "Web Proxy",
            value: "proxy",
          },
          {
            label: "Amazon S3 Bucket",
            value: "aws",
          },
          {
            label: "DigitalOcean Spaces Bucket",
            value: "dostorage",
          },
          {
            label: "Wasabi Storage",
            value: "wasabi",
          },
          {
            label: "Google Cloud Storage",
            value: "gcs",
          },
          {
            label: "Azure Storage",
            value: "azure",
          },
          {
            label: "Linode Storage",
            value: "linode",
          },
          {
            label: "Backblaze Storage",
            value: "backblaze",
          },
        ],
        description:
          "Source type gives us information about which storage system you use for your video storage. We fetch master videos from the source you configure here.",
        tool_tip: "https://docs.gumlet.com/docs/configure-image-source",
      },
    ],
  },
  magento: {
    image: "https://www.gumlet.com/public/dashboard/img/magento.svg",
    maxHeight: "3rem",
    maxWidth: "",
    title: "Magento",
    description: "Magento Online Commerce sites",
    url_key: "magento",
    defaultSelected: {
      label: "Web Folder",
      value: "webfolder",
    },
    fields: [
      {
        name: "Source type",
        type: "select",
        placeholder: "Select",
        api_key: "option",
        options: [
          {
            label: "Web Folder",
            value: "webfolder",
          },
          {
            label: "Web Proxy",
            value: "proxy",
          },
          {
            label: "Amazon S3 Bucket",
            value: "aws",
          },
          {
            label: "DigitalOcean Spaces Bucket",
            value: "dostorage",
          },
          {
            label: "Wasabi Storage",
            value: "wasabi",
          },
          {
            label: "Google Cloud Storage",
            value: "gcs",
          },
          {
            label: "Azure Storage",
            value: "azure",
          },
          {
            label: "Linode Storage",
            value: "linode",
          },
          {
            label: "Backblaze Storage",
            value: "backblaze",
          },
        ],
        description:
          "Source type gives us information about which storage system you use for your image storage. We fetch master videos from the source you configure here.",
        tool_tip: "https://docs.gumlet.com/docs/configure-image-source",
      },
    ],
  },
};

export const customSelectDropdownStyles = (theme, maxHeight) => {
  return {
    control: (base, state) => ({
      color: theme === "dark" ? "#ffff" : "hsl(0, 0%, 100%)",
      ...base,
      cursor: "pointer",
      boxShadow: state.isFocused ? "0 0 8px rgb(55 125 255 / 10%)" : "",
      "&:hover": {
        backgroundColor: "none",
      },
      borderRadius: ".3125rem",
      position: "relative",
      backgroundColor: theme === "dark" ? "#15181f" : "hsl(0, 0%, 100%)",
      width: "100%",
      minHeight: maxHeight?.controlHeight ? maxHeight?.controlHeight : "42px",
      border:
        theme === "dark" ? ".0425rem solid #2f3235" : ".0625rem solid #e7eaf3",
    }),
    container: (base) => ({
      ...base,
      width: maxHeight?.width ? maxHeight?.width : "100%",
      position: "relative",
    }),
    menu: () => ({
      width: "100%",
      borderRadius: ".3125rem",
      backgroundColor: theme === "dark" ? "#15181f" : "white",
      border: theme === "dark" ? "1px solid #2f3235" : "1px solid #e7eaf3",
      marginTop: ".5rem",
      position: "absolute",
      top: "35",
      zIndex: "10",
    }),
    menuList: () => ({
      width: "100%",
      padding: 0,
      overflowY: "scroll",
      maxHeight: maxHeight?.maxHeight ? maxHeight?.maxHeight : "180px",
    }),
    placeholder: () => ({
      marginLeft: "10px",
      marginRight: "2px",
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: "100%",
      color: theme === "dark" ? "#c5c8cc" : "#8c98a4",
      opacity: "0.445",
    }),
    option: (base, state) => ({
      ...base,
      "&:hover": {
        backgroundColor:
          theme === "dark"
            ? state.isSelected
              ? "1E2021"
              : "rgba(43,45,52,0.4)"
            : state.isSelected
            ? "F8F8F8"
            : "rgba(189,197,209,.3)",
      },
      backgroundColor:
        theme === "dark"
          ? state.isSelected
            ? "#282c3a"
            : "#15181f"
          : state.isSelected
          ? "#F8F8F8"
          : "#FFFFFF",
      color:
        theme === "dark"
          ? state.isSelected
            ? "#939393"
            : "#fffff"
          : state.isSelected
          ? "#6e6e6e"
          : "#010101",
      //-backgroundColor: state.isSelected ? "text-light" : "bg-white",
      cursor: "pointer",
      position: "relative",
      width: "100%",
    }),
    valueContainer: (base) => ({
      ...base,
      flex: 1,
      display: "flex",
      position: "relative",
      overflow: "hidden",
      alignItems: "center",
      flexWrap: "none",
      width: "100%",
      flexWrap: "none",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    indicatorsContainer: (base) => ({
      ...base,
      display: "flex",
      alignSelf: "auto",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      height: "32px",
      width: "32px",
      padding: "7px",
      color: state.isSelected ? "hsl(0, 0%, 80%)" : "hsl(0, 0%, 80%)",
    }),
    multiValue: (base, state) => ({
      ...base,
      position: "relative",
      backgroundColor: theme !== "dark" ? "#e8eaf3" : "#2b2d34",
      borderColor: "transparent",
      paddingRight: "1.6125rem",
      float: "left",
      lineHeight: "normal",
      whiteSpace: "pre-line",
      margin: "0.25rem",
      padding: "0.2125rem 0.575rem",
      borderRadius: "4px",
      alignItems: "center",
      closeMenuOnSelect: false,
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: theme !== "dark" ? "#1e2022" : "#e8e8e8",
      fontSize: "0.875rem",
      fontWeight: "400",
    }),
    multiValueRemove: (base) => ({
      ...base,
      color: "#90989d",
      paddingLeft: 0,
      paddingRight: 0,
      "&:hover": {
        color: "#333",
        backgroundColor: "transparent",
      },
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      const paddingRight = 0;
      const width = "100%";
      const marginLeft = "10px";
      const color = theme === "dark" ? "#c5c8cc" : "hsl(0, 0%, 20%)";

      return {
        ...provided,
        opacity,
        transition,
        paddingRight,
        width,
        marginLeft,
        color,
      };
    },
    input: (base) => ({
      ...base,
      color: theme === "dark" ? "#e8eaf3" : "#2b2d34",
    }),
  };
};

export const Metrics = [
  {
    label: "Bandwidth (GBs)",
    value: "bandwidth",
  },
  {
    label: "Total Requests",
    value: "requests",
  },
  {
    label: "2xx Status",
    value: "status_2xx",
  },
  {
    label: "4xx Status",
    value: "status_4xx",
  },
  {
    label: "5xx Status",
    value: "status_4xx",
  },
  {
    label: "Hit Ratio",
    value: "hit_ratio",
  },
  {
    label: "Response Time",
    value: "response_time",
  },
];

export const Frequencies = [
  {
    label: "Daily",
    value: "daily",
  },
  {
    label: "Weekly",
    value: "weekly",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
];

export const loginOptions = [
  {
    label: "Google Auth",
    value: "google",
  },
  {
    label: "Password Auth",
    value: "password",
  },
  {
    label: "Github Auth",
    value: "github",
  },
  {
    label: "Microsoft Auth",
    value: "microsoft",
  },
];

export const assignable_roles = [
  {
    label: "Owner Has - all permissions",
    value: "owner",
  },
  {
    label:
      "Image Admin - Has all image related permissions except user managment",
    value: "admin",
  },
  {
    label: "Image Developer - Can view and modify image sources",
    value: "developer",
  },
  {
    label: "Image Analyst - Can view image analytics",
    value: "analyst",
  },
  {
    label: "Accountant - Can view and manage billing",
    value: "accountant",
  },
  {
    label:
      "Image Source Specific - Can view and modify specified image source and view its analytics",
    value: "source-specific",
  },
  {
    label:
      "Video Developer - Can view and modify video collections and profiles",
    value: "video-developer",
  },
  {
    label: "Video Analyst - Can view video analytics",
    value: "video-analyst",
  },
  {
    label:
      "Video Admin - Has all video related permissions except user managment",
    value: "video-admin",
  },
  {
    label:
      "Video collection Specific - Can view and modify specified video collection and view its analytics",
    value: "video-source-specific",
  },
  {
    label: "Video Readonly - Can view Video CMS dashboard",
    value: "video-readonly",
  },
];

export const countryList = [
  { label: "United States", value: "US" },
  { label: "United Kingdom", value: "GB" },
  { label: "Albania", value: "AL" },
  { label: "Åland Islands", value: "AX" },
  { label: "Algeria", value: "DZ" },
  { label: "American Samoa", value: "AS" },
  { label: "Andorra", value: "AD" },
  { label: "Angola", value: "AO" },
  { label: "Anguilla", value: "AI" },
  { label: "Antarctica", value: "AQ" },
  { label: "Antigua and Barbuda", value: "AG" },
  { label: "Argentina", value: "AR" },
  { label: "Armenia", value: "AM" },
  { label: "Aruba", value: "AW" },
  { label: "Australia", value: "AU" },
  { label: "Austria", value: "AT" },
  { label: "Azerbaijan", value: "AZ" },
  { label: "Bahamas (the)", value: "BS" },
  { label: "Bahrain", value: "BH" },
  { label: "Bangladesh", value: "BD" },
  { label: "Barbados", value: "BB" },
  { label: "Belarus", value: "BY" },
  { label: "Belgium", value: "BE" },
  { label: "Belize", value: "BZ" },
  { label: "Benin", value: "BJ" },
  { label: "Bermuda", value: "BM" },
  { label: "Bhutan", value: "BT" },
  { label: "Bolivia (Plurinational State of)", value: "BO" },
  { label: "Bonaire, Sint Eustatius and Saba", value: "BQ" },
  { label: "Bosnia and Herzegovina", value: "BA" },
  { label: "Botswana", value: "BW" },
  { label: "Bouvet Island", value: "BV" },
  { label: "Brazil", value: "BR" },
  { label: "British Indian Ocean Territory (the)", value: "IO" },
  { label: "Brunei Darussalam", value: "BN" },
  { label: "Bulgaria", value: "BG" },
  { label: "Burkina Faso", value: "BF" },
  { label: "Burundi", value: "BI" },
  { label: "Cabo Verde", value: "CV" },
  { label: "Cambodia", value: "KH" },
  { label: "Cameroon", value: "CM" },
  { label: "Canada", value: "CA" },
  { label: "Cayman Islands (the)", value: "KY" },
  { label: "Central African Republic (the)", value: "CF" },
  { label: "Chad", value: "TD" },
  { label: "Chile", value: "CL" },
  { label: "China", value: "CN" },
  { label: "Christmas Island", value: "CX" },
  { label: "Cocos (Keeling) Islands (the)", value: "CC" },
  { label: "Colombia", value: "CO" },
  { label: "Comoros (the)", value: "KM" },
  { label: "Congo (the Democratic Republic of the)", value: "CD" },
  { label: "Congo (the)", value: "CG" },
  { label: "Cook Islands (the)", value: "CK" },
  { label: "Costa Rica", value: "CR" },
  { label: "Croatia", value: "HR" },
  { label: "Cuba", value: "CU" },
  { label: "Curaçao", value: "CW" },
  { label: "Cyprus", value: "CY" },
  { label: "Czechia", value: "CZ" },
  { label: "Côte d'Ivoire", value: "CI" },
  { label: "Denmark", value: "DK" },
  { label: "Djibouti", value: "DJ" },
  { label: "Dominica", value: "DM" },
  { label: "Dominican Republic (the)", value: "DO" },
  { label: "Ecuador", value: "EC" },
  { label: "Egypt", value: "EG" },
  { label: "El Salvador", value: "SV" },
  { label: "Equatorial Guinea", value: "GQ" },
  { label: "Eritrea", value: "ER" },
  { label: "Estonia", value: "EE" },
  { label: "Eswatini", value: "SZ" },
  { label: "Ethiopia", value: "ET" },
  { label: "Falkland Islands (the) [Malvinas]", value: "FK" },
  { label: "Faroe Islands (the)", value: "FO" },
  { label: "Fiji", value: "FJ" },
  { label: "Finland", value: "FI" },
  { label: "France", value: "FR" },
  { label: "French Guiana", value: "GF" },
  { label: "French Polynesia", value: "PF" },
  { label: "French Southern Territories (the)", value: "TF" },
  { label: "Gabon", value: "GA" },
  { label: "Gambia (the)", value: "GM" },
  { label: "Georgia", value: "GE" },
  { label: "Germany", value: "DE" },
  { label: "Ghana", value: "GH" },
  { label: "Gibraltar", value: "GI" },
  { label: "Greece", value: "GR" },
  { label: "Greenland", value: "GL" },
  { label: "Grenada", value: "GD" },
  { label: "Guadeloupe", value: "GP" },
  { label: "Guam", value: "GU" },
  { label: "Guatemala", value: "GT" },
  { label: "Guernsey", value: "GG" },
  { label: "Guinea", value: "GN" },
  { label: "Guinea-Bissau", value: "GW" },
  { label: "Guyana", value: "GY" },
  { label: "Haiti", value: "HT" },
  { label: "Heard Island and McDonald Islands", value: "HM" },
  { label: "Holy See (the)", value: "VA" },
  { label: "Honduras", value: "HN" },
  { label: "Hong Kong", value: "HK" },
  { label: "Hungary", value: "HU" },
  { label: "Iceland", value: "IS" },
  { label: "India", value: "IN" },
  { label: "Indonesia", value: "ID" },
  { label: "Iran (Islamic Republic of)", value: "IR" },
  { label: "Iraq", value: "IQ" },
  { label: "Ireland", value: "IE" },
  { label: "Isle of Man", value: "IM" },
  { label: "Israel", value: "IL" },
  { label: "Italy", value: "IT" },
  { label: "Jamaica", value: "JM" },
  { label: "Japan", value: "JP" },
  { label: "Jersey", value: "JE" },
  { label: "Jordan", value: "JO" },
  { label: "Kazakhstan", value: "KZ" },
  { label: "Kenya", value: "KE" },
  { label: "Kiribati", value: "KI" },
  { label: "Korea (the Democratic People's Republic of)", value: "KP" },
  { label: "Korea (the Republic of)", value: "KR" },
  { label: "Kuwait", value: "KW" },
  { label: "Kyrgyzstan", value: "KG" },
  { label: "Lao People's Democratic Republic (the)", value: "LA" },
  { label: "Latvia", value: "LV" },
  { label: "Lebanon", value: "LB" },
  { label: "Lesotho", value: "LS" },
  { label: "Liberia", value: "LR" },
  { label: "Libya", value: "LY" },
  { label: "Liechtenstein", value: "LI" },
  { label: "Lithuania", value: "LT" },
  { label: "Luxembourg", value: "LU" },
  { label: "Macao", value: "MO" },
  { label: "Madagascar", value: "MG" },
  { label: "Malawi", value: "MW" },
  { label: "Malaysia", value: "MY" },
  { label: "Maldives", value: "MV" },
  { label: "Mali", value: "ML" },
  { label: "Malta", value: "MT" },
  { label: "Marshall Islands (the)", value: "MH" },
  { label: "Martinique", value: "MQ" },
  { label: "Mauritania", value: "MR" },
  { label: "Mauritius", value: "MU" },
  { label: "Mayotte", value: "YT" },
  { label: "Mexico", value: "MX" },
  { label: "Micronesia (Federated States of)", value: "FM" },
  { label: "Moldova (the Republic of)", value: "MD" },
  { label: "Monaco", value: "MC" },
  { label: "Mongolia", value: "MN" },
  { label: "Montenegro", value: "ME" },
  { label: "Montserrat", value: "MS" },
  { label: "Morocco", value: "MA" },
  { label: "Mozambique", value: "MZ" },
  { label: "Myanmar", value: "MM" },
  { label: "Namibia", value: "NA" },
  { label: "Nauru", value: "NR" },
  { label: "Nepal", value: "NP" },
  { label: "Netherlands (the)", value: "NL" },
  { label: "New Caledonia", value: "NC" },
  { label: "New Zealand", value: "NZ" },
  { label: "Nicaragua", value: "NI" },
  { label: "Niger (the)", value: "NE" },
  { label: "Nigeria", value: "NG" },
  { label: "Niue", value: "NU" },
  { label: "Norfolk Island", value: "NF" },
  { label: "Northern Mariana Islands (the)", value: "MP" },
  { label: "Norway", value: "NO" },
  { label: "Oman", value: "OM" },
  { label: "Pakistan", value: "PK" },
  { label: "Palau", value: "PW" },
  { label: "Palestine, State of", value: "PS" },
  { label: "Panama", value: "PA" },
  { label: "Papua New Guinea", value: "PG" },
  { label: "Paraguay", value: "PY" },
  { label: "Peru", value: "PE" },
  { label: "Philippines (the)", value: "PH" },
  { label: "Pitcairn", value: "PN" },
  { label: "Poland", value: "PL" },
  { label: "Portugal", value: "PT" },
  { label: "Puerto Rico", value: "PR" },
  { label: "Qatar", value: "QA" },
  { label: "Republic of North Macedonia", value: "MK" },
  { label: "Romania", value: "RO" },
  { label: "Russian Federation (the)", value: "RU" },
  { label: "Rwanda", value: "RW" },
  { label: "Réunion", value: "RE" },
  { label: "Saint Barthélemy", value: "BL" },
  { label: "Saint Helena, Ascension and Tristan da Cunha", value: "SH" },
  { label: "Saint Kitts and Nevis", value: "KN" },
  { label: "Saint Lucia", value: "LC" },
  { label: "Saint Martin (French part)", value: "MF" },
  { label: "Saint Pierre and Miquelon", value: "PM" },
  { label: "Saint Vincent and the Grenadines", value: "VC" },
  { label: "Samoa", value: "WS" },
  { label: "San Marino", value: "SM" },
  { label: "Sao Tome and Principe", value: "ST" },
  { label: "Saudi Arabia", value: "SA" },
  { label: "Senegal", value: "SN" },
  { label: "Serbia", value: "RS" },
  { label: "Seychelles", value: "SC" },
  { label: "Sierra Leone", value: "SL" },
  { label: "Singapore", value: "SG" },
  { label: "Sint Maarten (Dutch part)", value: "SX" },
  { label: "Slovakia", value: "SK" },
  { label: "Slovenia", value: "SI" },
  { label: "Solomon Islands", value: "SB" },
  { label: "Somalia", value: "SO" },
  { label: "South Africa", value: "ZA" },
  { label: "South Georgia and the South Sandwich Islands", value: "GS" },
  { label: "South Sudan", value: "SS" },
  { label: "Spain", value: "ES" },
  { label: "Sri Lanka", value: "LK" },
  { label: "Sudan (the)", value: "SD" },
  { label: "Surilabel", value: "SR" },
  { label: "Svalbard and Jan Mayen", value: "SJ" },
  { label: "Sweden", value: "SE" },
  { label: "Switzerland", value: "CH" },
  { label: "Syrian Arab Republic", value: "SY" },
  { label: "Taiwan (Province of China)", value: "TW" },
  { label: "Tajikistan", value: "TJ" },
  { label: "Tanzania, United Republic of", value: "TZ" },
  { label: "Thailand", value: "TH" },
  { label: "Timor-Leste", value: "TL" },
  { label: "Togo", value: "TG" },
  { label: "Tokelau", value: "TK" },
  { label: "Tonga", value: "TO" },
  { label: "Trinidad and Tobago", value: "TT" },
  { label: "Tunisia", value: "TN" },
  { label: "Turkey", value: "TR" },
  { label: "Turkmenistan", value: "TM" },
  { label: "Turks and Caicos Islands (the)", value: "TC" },
  { label: "Tuvalu", value: "TV" },
  { label: "Uganda", value: "UG" },
  { label: "Ukraine", value: "UA" },
  { label: "United Arab Emirates (the)", value: "AE" },
  { label: "United States Minor Outlying Islands", value: "UM" },
  { label: "Uruguay", value: "UY" },
  { label: "Uzbekistan", value: "UZ" },
  { label: "Vanuatu", value: "VU" },
  { label: "Venezuela (Bolivarian Republic of)", value: "VE" },
  { label: "Viet Nam", value: "VN" },
  { label: "Virgin Islands (British)", value: "VG" },
  { label: "Virgin Islands (U.S.)", value: "VI" },
  { label: "Wallis and Futuna", value: "WF" },
  { label: "Western Sahara", value: "EH" },
  { label: "Yemen", value: "YE" },
  { label: "Zambia", value: "ZM" },
  { label: "Zimbabwe", value: "ZW" },
];

export const pricing = [
  {
    plan: "free",
    price: "Free",
    yearlyPrice: "Free",
    title: "For trials and hobby projects",
    details: [
      {
        title: "Video Analytics",
      },
      {
        title: "Video CMS",
      },
      {
        title: "1000 mins of video streaming",
      },
      {
        title: "1M mins of video analytics",
      },
      {
        title: "30GB of optimized image delivery",
      },
    ],
    btnTxt: "Select Plan",
  },
  {
    plan: "growth",
    price: "$120",
    yearlyPrice: "$99",
    per: "/mon",
    title: "For small website owner",
    details: [
      {
        title: "Video protection",
      },
      {
        title: "1500 mins of video upload",
      },
      {
        title: "10k mins of video streaming",
      },
      {
        title: "1TB of optimized image delivery",
      },
      {
        title: "Chat and email support",
      },
    ],
    btnTxt: "Select Plan",
  },
  {
    plan: "enterprise",
    price: "",
    yearlyPrice: "",
    title: "For large business with 3TB or above usage",
    details: [
      {
        title: "Custom integration with the existing stack",
      },
      {
        title: "Dedicated onboarding & support",
      },
      {
        title: "99.99% SLA with multi-CDN",
      },
      {
        title: "Volume discounts",
      },
      {
        title: "Payment terms and methods",
      },
    ],
    btnTxt: "Book a Meeting",
  },
];

export const renderSource = (source) => {
  if (source.type === "webfolder") {
    return (
      <span>
        <i className="fas fa-folder-open me-1" />
        &nbsp;Web Folder
      </span>
    );
  } else if (source.type === "aws") {
    return (
      <span>
        <i className="fab fa-aws me-1" />
        &nbsp;Amazon S3
      </span>
    );
  } else if (source.type === "wasabi") {
    return (
      <span>
        <i className="tio-upload-on-cloud me-1" />
        &nbsp;Wasabi Storage
      </span>
    );
  } else if (source.type === "dostorage") {
    return (
      <span>
        <i className="fab fa-digital-ocean me-1" />
        &nbsp;DigitalOcean Spaces
      </span>
    );
  } else if (source.type === "gcs") {
    return (
      <span>
        <i className="tio-upload-on-cloud me-1" />
        &nbsp;Google Cloud Storage
      </span>
    );
  } else if (source.type === "proxy") {
    return (
      <span>
        <i className="fas fa-server me-1" />
        &nbsp;Web Proxy
      </span>
    );
  } else if (source.type === "cloudinary") {
    return (
      <span>
        <i className="fas fa-server me-1" />
        &nbsp; Cloudinary
      </span>
    );
  } else if (source.type === "wordpress") {
    return (
      <span>
        <i className="fab fa-wordpress-simple me-1" />
        &nbsp;WordPress
      </span>
    );
  } else if (source.type === "azure") {
    return (
      <span>
        <i className="tio-upload-on-cloud me-1" />
        &nbsp;Azure Cloud Storage
      </span>
    );
  } else if (source.type === "linode") {
    return (
      <span>
        <i className="fab fa-linode me-1" />
        &nbsp;Linode Cloud Storage
      </span>
    );
  } else if (source.type === "backblaze") {
    return (
      <span>
        <i className="tio-upload-on-cloud me-1" />
        &nbsp;Backblaze Storage
      </span>
    );
  } else if (source.type === "direct-upload") {
    return (
      <span>
        <i className="tio-upload-on-cloud me-1" />
        &nbsp;Direct Upload
      </span>
    );
  } else if (source.type === "cloudflare") {
    return (
      <span>
        <i className="tio-upload-on-cloud me-1" />
        &nbsp;Cloudflare
      </span>
    );
  } else if (source.type === "zoom") {
    return (
      <span>
        <i className="tio-upload-on-cloud me-1" />
        &nbsp;Zoom
      </span>
    );
  }
};

export const renderSourceUrls = (source) => {
  if (source.type === "webfolder") {
    return source.webfolder.base_url;
  } else if (source.type === "aws") {
    return `s3://${source.aws.bucket_name}${source.aws.base_path}`;
  } else if (source.type === "wasabi") {
    return `s3://${source.wasabi.bucket_name}${source.wasabi.base_path}`;
  } else if (source.type === "dostorage") {
    return `do://${source.dostorage.bucket_name}${source.dostorage.base_path}`;
  } else if (source.type === "gcs") {
    return `gs://${source.gcs.bucket_name}`;
  } else if (source.type === "proxy") {
    return <></>;
  } else if (source.type === "cloudinary") {
    return `https://res.cloudinary.com/${source.cloudinary.cloud_name}/image/upload`;
  } else if (source.type === "wordpress") {
    return source.wordpress.website_url;
  } else if (source.type === "azure") {
    return `https://${source.azure.azure_account_name}.blob.core.windows.net/${source.azure.azure_container_name}/${source.azure.azure_path}`;
  } else if (source.type === "linode") {
    return `https://${source.linode.bucket_name}.${source.linode.bucket_region}.linodeobjects.com`;
  } else if (source.type === "backblaze") {
    return `s3://${source.backblaze.bucket_name}${source.backblaze.base_path}`;
  }
};

export const default_parameters = [
  {
    label: "width",
    value: "width",
    val: "",
  },
  {
    label: "height",
    value: "height",
    val: "",
  },
  {
    label: "dpr",
    value: "dpr",
    val: "",
  },
  {
    label: "dpi",
    value: "dpi",
    val: "",
  },
  {
    label: "enlarge",
    value: "enlarge",
    val: "",
  },
  {
    label: "mode",
    value: "mode",
    val: "",
  },
  {
    label: "fill",
    value: "fill",
    val: "",
  },
  {
    label: "fill-color",
    value: "fill-color",
    val: "",
  },
  {
    label: "crop",
    value: "crop",
    val: "",
  },
  {
    label: "extract",
    value: "extract",
    val: "",
  },
  {
    label: "aspect ratio (ar)",
    value: "ar",
    val: "",
  },
  {
    label: "pad",
    value: "pad",
    val: "",
  },
  {
    label: "trim",
    value: "trim",
    val: "",
  },
  {
    label: "metadata",
    value: "metadata",
    val: "",
  },
  {
    label: "quality (q)",
    value: "q",
    val: "",
  },
  {
    label: "subsample",
    value: "subsample",
    val: "",
  },
  {
    label: "page",
    value: "page",
    val: "",
  },
  {
    label: "progressive",
    value: "progressive",
    val: "",
  },
  {
    label: "AVIF Enabled",
    value: "avif",
    val: "",
  },
  {
    label: "colorspace",
    value: "colorspace",
    val: "",
  },
  {
    label: "bg - background",
    value: "bg",
    val: "",
  },
  {
    label: "bri - brightness",
    value: "bri",
    val: "",
  },
  {
    label: "sat - saturation",
    value: "sat",
    val: "",
  },
  {
    label: "hue",
    value: "hue",
    val: "",
  },
  {
    label: "tint",
    value: "tint",
    val: "",
  },
  {
    label: "grayscale",
    value: "grayscale",
    val: "",
  },
  {
    label: "invert",
    value: "invert",
    val: "",
  },
  {
    label: "enhance",
    value: "enhance",
    val: "",
  },
  {
    label: "rotate",
    value: "rotate",
    val: "",
  },
  {
    label: "flip",
    value: "flip",
    val: "",
  },
  {
    label: "blur",
    value: "blur",
    val: "",
  },
  {
    label: "threshold",
    value: "threshold",
    val: "",
  },
  {
    label: "gamma",
    value: "gamma",
    val: "",
  },
  {
    label: "sharp",
    value: "sharp",
    val: "",
  },
  {
    label: "overlay",
    value: "overlay",
    val: "",
  },
  {
    label: "overlay_position",
    value: "overlay_position",
    val: "",
  },
  {
    label: "overlay_top",
    value: "overlay_top",
    val: "",
  },
  {
    label: "overlay_left",
    value: "overlay_left",
    val: "",
  },
  {
    label: "overlay_tile",
    value: "overlay_tile",
    val: "",
  },
  {
    label: "overlay_height_pct",
    value: "overlay_height_pct",
    val: "",
  },
  {
    label: "overlay_width_pct",
    value: "overlay_width_pct",
    val: "",
  },
  {
    label: "text",
    value: "text",
    val: "",
  },
  {
    label: "text_top",
    value: "text_top",
    val: "",
  },
  {
    label: "text_left",
    value: "text_left",
    val: "",
  },
  {
    label: "text_font",
    value: "text_font",
    val: "",
  },
  {
    label: "text_font_size",
    value: "text_font_size",
    val: "",
  },
  {
    label: "text_line_height",
    value: "text_line_height",
    val: "",
  },
  {
    label: "text_align",
    value: "text_align",
    val: "",
  },
  {
    label: "text_color",
    value: "text_color",
    val: "",
  },
  {
    label: "text_bg_color",
    value: "text_bg_color",
    val: "",
  },
  {
    label: "text_padding",
    value: "text_padding",
    val: "",
  },
  {
    label: "text_position",
    value: "text_position",
    val: "",
  },
  {
    label: "text_wrap",
    value: "text_wrap",
    val: "",
  },
  {
    label: "text_width_pct",
    value: "text_width_pct",
    val: "",
  },
  {
    label: "text_height_pct",
    value: "text_height_pct",
    val: "",
  },
  {
    label: "watermark",
    value: "watermark",
    val: "",
  },
  {
    label: "watermark_position",
    value: "watermark_position",
    val: "",
  },
];

export const souceOptions = (query) => {
  if (query === "magento" || query === "imgix") {
    return [
      {
        label: "Web Folder",
        value: "webfolder",
      },
      {
        label: "Web Proxy",
        value: "proxy",
      },
      {
        label: "Amazon S3 Bucket",
        value: "aws",
      },
      {
        label: "DigitalOcean Spaces Bucket",
        value: "dostorage",
      },
      {
        label: "Wasabi Storage",
        value: "wasabi",
      },
      {
        label: "Google Cloud Storage",
        value: "gcs",
      },
      {
        label: "Azure Storage",
        value: "azure",
      },
      {
        label: "Linode Storage",
        value: "linode",
      },
      {
        label: "Backblaze Storage",
        value: "backblaze",
      },
    ];
  } else if (query === "web") {
    return [
      {
        label: "Web Folder",
        value: "webfolder",
      },
      {
        label: "Web Proxy",
        value: "proxy",
      },
    ];
  } else if (query === "cloud") {
    return [
      {
        label: "Amazon S3 Bucket",
        value: "aws",
      },
      {
        label: "DigitalOcean Spaces Bucket",
        value: "dostorage",
      },
      {
        label: "Wasabi Storage",
        value: "wasabi",
      },
      {
        label: "Google Cloud Storage",
        value: "gcs",
      },
      {
        label: "Azure Storage",
        value: "azure",
      },
      {
        label: "Linode Storage",
        value: "linode",
      },
      {
        label: "Backblaze Storage",
        value: "backblaze",
      },
    ];
  }
};

export const souceOptionsEditForm = [
  {
    label: "Web Folder",
    value: "webfolder",
  },
  {
    label: "Web Proxy",
    value: "proxy",
  },
  {
    label: "Amazon S3 Bucket",
    value: "aws",
  },
  {
    label: "DigitalOcean Spaces Bucket",
    value: "dostorage",
  },
  {
    label: "Wasabi Storage",
    value: "wasabi",
  },
  {
    label: "Google Cloud Storage",
    value: "gcs",
  },
  {
    label: "Azure Storage",
    value: "azure",
  },
  {
    label: "Linode Storage",
    value: "linode",
  },
  {
    label: "Backblaze Storage",
    value: "backblaze",
  },
];
export const awsBucketRegion = [
  {
    label: "Asia Pacific (Mumbai) ap-south-1",
    value: "ap-south-1",
  },
  {
    label: "Asia Pacific (Seoul) ap-northeast-2",
    value: "ap-northeast-2",
  },
  {
    label: "Asia Pacific (Singapore) ap-southeast-1",
    value: "ap-southeast-1",
  },
  {
    label: "Asia Pacific (Sydney) ap-southeast-2",
    value: "ap-southeast-2",
  },
  {
    label: "Asia Pacific (Tokyo) ap-northeast-1",
    value: "ap-northeast-1",
  },
  {
    label: "Canada (Central) ca-central-1",
    value: "ca-central-1",
  },
  {
    label: "EU (Frankfurt) eu-central-1",
    value: "eu-central-1",
  },
  {
    label: "EU (Ireland) eu-west-1",
    value: "eu-west-1",
  },
  {
    label: "EU (London) eu-west-2",
    value: "eu-west-2",
  },
  {
    label: "EU (Paris) eu-west-3",
    value: "eu-west-3",
  },
  {
    label: "EU (StockHolm) eu-north-1",
    value: "eu-north-1",
  },
  {
    label: "South America (São Paulo) sa-east-1",
    value: "sa-east-1",
  },
  {
    label: "US East (N. Virginia) us-east-1",
    value: "us-east-1",
  },
  {
    label: "US East (Ohio) us-east-2",
    value: "us-east-2",
  },
  {
    label: "US West (N. California) us-west-1",
    value: "us-west-1",
  },
  {
    label: "US West (Oregon) us-west-2",
    value: "us-west-2",
  },
];

export const dostorageRegion = [
  {
    label: "New York (nyc3)",
    value: "nyc3",
  },
  {
    label: "Amsterdam York (ams3)",
    value: "ams3",
  },
  {
    label: "Singapore (sgp1)",
    value: "sgp1",
  },
  {
    label: "Bengaluru (blr1)",
    value: "blr1",
  },
  {
    label: "San Francisco York (sfo2)",
    value: "sfo2",
  },
  {
    label: "Frankfurt (fra1)",
    value: "fra1",
  },
];

export const wasabiRegion = [
  {
    label: "Oregon (us-west-1)",
    value: "us-west-1",
  },
  {
    label: "North Virginia (us-east-1)",
    value: "us-east-1",
  },
  {
    label: "North Virginia (us-east-2)",
    value: "us-east-2",
  },
  {
    label: "Amsterdam (eu-central-1)",
    value: "eu-central-1",
  },
  {
    label: "Singapore (ap-southeast-1)",
    value: "ap-southeast-1",
  },
  {
    label: "Plano, TX (us-central-1)",
    value: "us-central-1",
  },
  {
    label: "Toronto (ca-central-1)",
    value: "ca-central-1",
  },
  {
    label: "London (eu-west-1)",
    value: "eu-west-1",
  },
  {
    label: "Paris (eu-west-2)",
    value: "eu-west-2",
  },
  {
    label: "Frankfurt (eu-central-2)",
    value: "eu-central-2",
  },
  {
    label: "Tokyo (ap-northeast-1)",
    value: "ap-northeast-1",
  },
  {
    label: "Osaka (ap-northeast-2)",
    value: "ap-northeast-2",
  },
  {
    label: "Sydney (ap-southeast-2)",
    value: "ap-southeast-2",
  },
];

export const linodeRegion = [
  {
    label: "Singapore, SG",
    value: "ap-south-1",
  },
  {
    label: "Newark, NJ",
    value: "us-east-1",
  },
  {
    label: "Atlanta, GA",
    value: "us-southeast-1",
  },
  {
    label: "Frankfurt, DE",
    value: "eu-central-1",
  },
];

export const backblazeRegion = [
  {
    label: "us-west-001",
    value: "us-west-001",
  },
  {
    label: "us-west-002",
    value: "us-west-002",
  },
  {
    label: "us-west-003",
    value: "us-west-003",
  },
  {
    label: "us-west-004",
    value: "us-west-004",
  },
  {
    label: "us-west-005",
    value: "us-west-005",
  },
];

export const videoreportMetrics = {
  asset_duration: "Transcoding Minutes",
  storage_unit: "Storage Minutes",
  bandwidth_consumption: "Streaming GBs",
};

export const imagereportMetrics = {
  bandwidth: "Bandwidth (GBs)",
  requests: "Total Requests",
  status_2xx: "2xx Status",
  status_4xx: "4xx Status",
  status_5xx: "5xx Status",
  hit_ratio: "Hit Ratio",
  response_time: "Response Time",
};

export const setButtonTextAndUrl = (config, button_payload) => {
  // this handle outside button to set submit url text and submit api url
  if (config?.buttons) {
    // change button texts and routes
    config.buttons.submit_button_text = button_payload?.button_text;
    config.buttons.submit_api_url = button_payload?.api_route;
    config.buttons.save_button_icon = button_payload?.save_button_icon;
  }

  // api button text and api route set here.
  if (config.sections && config.sections[0].buttons) {
    if (button_payload?.button_text)
      config.sections[0].buttons[1].button_text = button_payload?.button_text;
    if (button_payload?.button_icon)
      config.sections[0].buttons[1].button_icon = button_payload?.button_icon;
    if (button_payload?.api_route)
      config.sections[0].buttons[1].submit_api_url = button_payload?.api_route;
    if (button_payload?.upgrade_button)
      config.sections[0].buttons[1].upgrade_button =
        button_payload?.upgrade_button;
  }
  return config;
};

export const seo = (data = {}) => {
  data.title = data.title || "Gumlet Dashboard";
  document.title = data.title;
};

export const onTabValueChange = (
  value,
  setActiveTab,
  searchParams,
  navigate,
  name
) => {
  setActiveTab(value);
  searchParams.set(name, value);
  navigate({
    search: searchParams.toString(),
  });
};

export const getDataOfPagination = async (req) => {
  let pagination_details = {};

  pagination_details.status = req.status ? req.status : "all";

  pagination_details.page = req.page ? parseInt(req.page) : 1;

  pagination_details.limit = req.limit ? parseInt(req.limit) : 10;

  pagination_details.start =
    (pagination_details.page - 1) * pagination_details.limit;

  pagination_details.end = pagination_details.page * pagination_details.limit;

  return pagination_details;
};

export const getTotalPage = (response, pagination_details) => {
  let totalPage;
  if (response % pagination_details.limit === 0) {
    totalPage = response / pagination_details.limit;
  } else {
    totalPage = Math.floor(response / pagination_details.limit) + 1;
  }

  return totalPage;
};

export const pagination = (currentPage, nrOfPages) => {
  var delta = 2,
    range = [],
    rangeWithDots = [],
    l;

  range.push(1);

  if (nrOfPages <= 1) {
    return range;
  }

  for (let i = currentPage - delta; i <= currentPage + delta; i++) {
    if (i < nrOfPages && i > 1) {
      range.push(i);
    }
  }
  range.push(nrOfPages);

  for (let i of range) {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1);
      } else if (i - l !== 1) {
        rangeWithDots.push("...");
      }
    }
    rangeWithDots.push(i);
    l = i;
  }

  return rangeWithDots;
};

export const getCollectionSequence = (collections, selectedCollectionId) => {
  let updatedData = [];
  let selectedMovedToFront = false;
  let selectedCollectionObject = collections.filter(
    (source) => source.id === selectedCollectionId
  )[0];
  collections.forEach((element, index) => {
    if (element.id === selectedCollectionId && index < 2) {
      updatedData.push(element);
      selectedMovedToFront = true;
    } else if (element.id !== selectedCollectionId) {
      updatedData.push(element);
    }
  });
  if (!selectedMovedToFront && selectedCollectionObject) {
    updatedData.splice(2, 0, selectedCollectionObject);
  }
  return updatedData;
};

export const convertUrlParamsToObject = (search) => {
  let result = search
    .slice(1)
    .split("&")
    .map((p) => p.split("="))
    .reduce((obj, pair) => {
      const [key, value] = pair.map(decodeURIComponent);
      obj[key] = value;
      return obj;
    }, {});

  return result;
};

// Get Video Durtation in 00:00:00 format
export const getVideoDuration = (item) => {
  //format video duration
  let duration;
  if (item.input && item.input.duration) {
    let results = formatDuration(item.input.duration, "video_cms");
    if (results.hour === 0) {
      duration = results.minute + ":" + results.second;
    } else if (results.hour !== 0) {
      duration = results.hour + ":" + results.minute + ":" + results.second;
    }
  } else {
    duration = "-";
  }
  return duration;
};

export const getVideoCreationDate = (item) => {
  let time = formatDistanceStrict(new Date(item.created_at), new Date(), {
    addSuffix: true,
  });
  if (time.includes("seconds")) {
    time = "Just now";
  }

  return time;
};

export const getAssetStatus = (item) => {
  if (item.status === "ready") {
    return (
      <span className="badge bg-soft-success text-success ">
        <span className="legend-indicator bg-success"></span>
        {item.status}
      </span>
    );
  } else if (item.status === "errored") {
    return (
      <span className="badge bg-soft-danger text-danger ">
        <span className="legend-indicator bg-danger"></span>
        {item.status}
      </span>
    );
  } else if (item.status === "quequed") {
    return (
      <span className="badge bg-soft-primary text-primary ">
        <span className="legend-indicator bg-primary"></span>
        {item.status}
      </span>
    );
  } else if (item.status === "upload-pending") {
    return (
      <span className="badge bg-soft-info text-info ">
        <span className="legend-indicator bg-info"></span>
        {item.status}
      </span>
    );
  } else {
    return (
      <span className="badge bg-soft-dark text-dark ">
        <span className="legend-indicator bg-dark"></span>
        {item.status}
      </span>
    );
  }
};

export const getApiQueryForCollectionAsset = (query) => {
  let formatQuery;

  formatQuery = query.title
    ? "?offset=" + 0 + "&size=" + query.limit
    : "?offset=" + (query.page - 1) * query.limit + "&size=" + query.limit;

  if (query.title) {
    formatQuery += "&title=" + query.title;
  }
  if (query.tag) {
    formatQuery += "&tag=" + query.tag;
  }
  if (query.status !== undefined && query.status !== "") {
    formatQuery += "&status=" + query.status;
  }
  if (query.start_date) {
    formatQuery += "&start_date=" + query.start_date;
  }
  if (query.end_date) {
    formatQuery += "&end_date=" + query.end_date;
  }
  if (query.min_duration) {
    formatQuery += "&min_duration=" + query.min_duration;
  }
  if (query.max_duration) {
    formatQuery += "&max_duration=" + query.max_duration;
  }
  if (query.sortBy) {
    formatQuery += "&sortBy=" + query.sortBy;
  }
  if (query.orderBy) {
    formatQuery += "&orderBy=" + query.orderBy;
  }

  return formatQuery;
};

export const statusArray = [
  {
    label: "Ready",
    value: "ready",
    status: "ready",
  },
  {
    label: "Processing",
    value: "processing",
    status: "processing",
  },
  {
    label: "Queued",
    value: "queued",
    status: "queued",
  },
  {
    label: "Downloading",
    value: "downloading",
    status: "downloading",
  },
  {
    label: "Errored",
    value: "errored",
    status: "errored",
  },
];

export const export_time = [
  {
    label: "00:00",
    value: "00",
  },
  {
    label: "01:00",
    value: "01",
  },
  {
    label: "02:00",
    value: "02",
  },
  {
    label: "03:00",
    value: "03",
  },
  {
    label: "04:00",
    value: "04",
  },
  {
    label: "05:00",
    value: "05",
  },
  {
    label: "06:00",
    value: "06",
  },
  {
    label: "07:00",
    value: "07",
  },
  {
    label: "08:00",
    value: "08",
  },
  {
    label: "09:00",
    value: "09",
  },
  {
    label: "10:00",
    value: "10",
  },
  {
    label: "11:00",
    value: "11",
  },
  {
    label: "12:00",
    value: "12",
  },
  {
    label: "13:00",
    value: "13",
  },
  {
    label: "14:00",
    value: "14",
  },
  {
    label: "15:00",
    value: "15",
  },
  {
    label: "16:00",
    value: "16",
  },
  {
    label: "17:00",
    value: "17",
  },
  {
    label: "18:00",
    value: "18",
  },
  {
    label: "19:00",
    value: "19",
  },
  {
    label: "20:00",
    value: "20",
  },
  {
    label: "21:00",
    value: "21",
  },
  {
    label: "22:00",
    value: "22",
  },
  {
    label: "23:00",
    value: "23",
  },
];

export const videoPricing = {
  title: "Video",
  description: "Deliver the perfect video experience to your audience",
  plans: [
    {
      id: "videoPlan2",
      plan: "Starter",
      price: "$19",
      yearlyPrice: "$10",
      per: "/mon",
      title: "For individuals, freelancers",
      details: [
        {
          title: "1200 minutes of storage",
        },
        {
          title: "2TB streaming / month",
        },
        {
          title: "$0.15 / GB streaming overage",
        },
        {
          title: "Video engagement tools",
        },
        {
          title: "Video chapters & SEO",
        },
        {
          title: "Engagement analytics",
        },
      ],
      btnTxt: "Upgrade to Starter",
    },
    {
      id: "videoPlan6",
      plan: "Growth",
      price: "$59",
      yearlyPrice: "$40",
      per: "/mon",
      title: "For marketers, creators, SMB's",
      details: [
        {
          title: "Everything in starter",
        },
        {
          title: "4000 minutes of storage",
        },
        {
          title: "$0.1 / GB streaming overage",
        },
        {
          title: "Advertising integrations",
        },
        {
          title: "Advanced video analytics",
        },
        {
          title: "API access (including zapier)",
        },
      ],
      btnTxt: "Upgrade to Growth",
    },
    {
      id: "videoPlan5",
      plan: "Business",
      price: "$259",
      yearlyPrice: "$199",
      per: "/mon",
      title: "For agencies, bussinesses",
      details: [
        {
          title: "Everything in growth",
        },
        {
          title: "15000 minutes of storage",
        },
        {
          title: "8TB streaming / month",
        },
        {
          title: "$0.04 / GB streaming overage",
        },
        {
          title: "DRM support",
        },
        {
          title: "VAST tag integration",
        },
      ],
      btnTxt: "Upgrade to Business",
    },
    {
      plan: "Custom",
      plan_name_alias: "enterprise",
      price: "",
      yearlyPrice: "",
      title: "For brands, video-first companies",
      details: [
        {
          title: "Everything in business",
        },
        {
          title: "Custom storage & bandwidth",
        },
        {
          title: "Dynamic watermarking",
        },
        {
          title: "Custom integrations",
        },
        {
          title: "Dedicated support",
        },
      ],
      btnTxt: "Talk to Expert",
    },
  ],
};

export const imagePricing = {
  title: "Image",
  description: "Deliver optimised, fast and high quality images across devices",
  plans: [
    {
      id: "imagePlan1",
      plan: "Free",
      price: "Free",
      yearlyPrice: "Free",
      title: "For personal projects",
      details: [
        {
          title: "2 image sources",
        },
        {
          title: "30GB bandwidth",
        },
        {
          title: "$0.50 / GB bandwidth overage",
        },
        {
          title: "Unlimited API calls",
        },
        {
          title: "Email support",
        },
      ],
      btnTxt: "Start for free",
    },
    {
      id: "imagePlan2",
      plan: "Growth",
      price: "$39",
      yearlyPrice: "$32",
      per: "/mon",
      title: "For wordpress, shopify, startups",
      details: [
        {
          title: "30 image sources",
        },
        {
          title: "300GB bandwidth",
        },
        {
          title: "$0.15 / GB bandwidth overage",
        },
        {
          title: "2 Custom domain names",
        },
        {
          title: "Unlimited API calls",
        },
      ],
      btnTxt: "Upgrade to Growth",
    },
    {
      id: "imagePlan3",
      plan: "Business",
      price: "$259",
      yearlyPrice: "$199",
      per: "/mon",
      title: "For wordpress, shopify, startups",
      details: [
        {
          title: "100 image sources",
        },
        {
          title: "2500GB bandwidth",
        },
        {
          title: "$0.08 / GB bandwidth overage",
        },
        {
          title: "2 Custom domain names",
        },
        {
          title: "Unlimited API calls",
        },
      ],
      btnTxt: "Upgrade to Business",
    },
    {
      plan: "Custom",
      plan_name_alias: "enterprise",
      price: "",
      yearlyPrice: "",
      title: "For agencies, enterprises",
      details: [
        {
          title: "Everything in growth",
        },
        {
          title: "Custom bandwidth",
        },
        {
          title: "Volume discounts",
        },
        {
          title: "Custom integrations",
        },
        {
          title: "AI features for image processing",
        },
      ],
      btnTxt: "Talk to Expert",
    },
  ],
};

export const videoFreeSummaryData = [
  {
    plan: "free",
    data: [
      {
        label: "Video Storage (mins total)",
        value: 100,
      },
      {
        label: "Video Streaming (GB/mo)",
        value: "250GB",
      },
      {
        label: "Streaming overage ($/GB)",
        value: "$0.50",
      },
    ],
  },
];

export const includePlans = {
  drmPlans: [
    "appsumo",
    "growth",
    "enterprise",
    "videoPlan4",
    "videoPlan5", //business
    "videoAppsumo5",
    "videoAppsumoBF5",
    "videoAppsumo24BF5",
    "videoAppsumo24BF6",
  ],
  appSumoPlans: [
    "videoAppsumo1",
    "videoAppsumo2",
    "videoAppsumo3",
    "videoAppsumo4",
    "videoAppsumo5",
    "videoAppsumoBF1",
    "videoAppsumoBF2",
    "videoAppsumoBF3",
    "videoAppsumoBF4",
    "videoAppsumoBF5",
    "videoAppsumo24BF1",
    "videoAppsumo24BF2",
    "videoAppsumo24BF3",
    "videoAppsumo24BF4",
    "videoAppsumo24BF5",
    "videoAppsumo24BF6",
  ],
  resolutionPlans: [
    "growth",
    "enterprise",
    "videoAppsumo5",
    "videoAppsumoBF5",
    "videoPlan2", //starter
    "videoPlan3", //growth
    "videoPlan4", //business
    "videoPlan6", //growth
    "videoPlan5", //business
  ],
  excludePerTitleEncodingPlans: [
    "videoPlan1",
    "videoAppsumo1",
    "videoAppsumo2",
    "videoAppsumo3",
    "videoAppsumo4",
    "videoAppsumo5",
    "videoAppsumoBF1",
    "videoAppsumoBF2",
    "videoAppsumoBF3",
    "videoAppsumoBF4",
    "videoAppsumoBF5",
    "videoAppsumo24BF1",
    "videoAppsumo24BF2",
    "videoAppsumo24BF3",
    "videoAppsumo24BF4",
    "videoAppsumo24BF5",
    "videoAppsumo24BF6",
  ],
  paidPlans: ["growth", "appsumo", "enterprise", "imagePlan2", "imagePlan3"],
  hevc: [
    "enterprise",
    "videoPlan5", //business
    "videoAppsumo5",
    "videoAppsumoBF5",
    "videoAppsumo24BF5",
    "videoAppsumo24BF6",
  ],
  geoBlock: [
    "videoPlan5",
    "enterprise",
    "videoAppsumo1",
    "videoAppsumo2",
    "videoAppsumo3",
    "videoAppsumo4",
    "videoAppsumo5",
    "videoAppsumoBF1",
    "videoAppsumoBF2",
    "videoAppsumoBF3",
    "videoAppsumoBF4",
    "videoAppsumoBF5",
    "videoAppsumo24BF1",
    "videoAppsumo24BF2",
    "videoAppsumo24BF3",
    "videoAppsumo24BF4",
    "videoAppsumo24BF5",
    "videoAppsumo24BF6",
  ],
};

export const ConvertHHMMSSFormatDuration = (seconds) => {
  var hours = Math.floor(seconds / 3600);
  var minutes = Math.floor((seconds % 3600) / 60);
  var remainingSeconds = seconds % 60;

  var duration = "";
  if (hours > 0) {
    duration += hours + " hr ";
  }
  if (minutes > 0) {
    duration += minutes + " min ";
  }
  if (remainingSeconds > 0 || duration === "") {
    duration += remainingSeconds + " sec";
  }

  return duration;
};

const regex =
  /\b(?:https?|ftp):\/\/[-\w+&@#/%?=~_|!:,.;]*[-\w+&@#/%=~_|]|mailto:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;

export const isValidUrl = (url) => {
  return regex.test(url);
};

export const passwordOptionsCMS = [
  {
    value: "public",
    label:
      "Anyone with the link can view it and may appear in search results on engines.",
    header: "Public",
  },
  {
    value: "password",
    label:
      "The viewers require the password that you set to access the video content.",
    header: "Password Protected",
  },
  {
    value: "private",
    label:
      "The search engines cannot index video. Anyone with the link can view it.",
    header: "Unlisted",
  },
  {
    value: "dashboardOnly",
    label:
      "Only your teammates can access the video inside the Gumlet dashboard.",
    header: "Private",
  },
];

export const passwordOptionsSource = [
  {
    value: "public",
    label:
      "Anyone with the link can view it and may appear in search results on engines.",
    header: "Public",
  },
  {
    value: "password-protected",
    label:
      "The viewers require the password that you set to access the video content.",
    header: "Password Protected",
  },
  {
    value: "private",
    label:
      "The search engines cannot index video. Anyone with the link can view it.",
    header: "Unlisted",
  },
  {
    value: "dashboardOnly",
    label:
      "Only your teammates can access the video inside the Gumlet dashboard.",
    header: "Private",
  },
];

export const formatDropdownLabel = (option) => {
  return (
    <div className="d-flex flex-column">
      <h5 className="value-header">{option.header}</h5>
      <div
        className="text-muted"
        style={{
          fontSize: "12px",
        }}
      >
        {option.label}
      </div>
    </div>
  );
};

export const formatDropdownOptionValue = (value) => {
  if (value) {
    return <h5 className="value-header">{value}</h5>;
  }
};

export function updateURLWithTimestamp(originalURL, time) {
  const modifiedURL =
    originalURL.split("?")[0] +
    `?v=${time}&format=auto&mode=fill&fill=solid&fill-color=f8fafd&w=500&h=281`;
  return modifiedURL;
}

export function addParameterToUrl(url, theme) {
  let urlObject = new URL(url);

  return theme === "dark"
    ? urlObject.toString().concat("&format=webp&w=500&dpr=2")
    : urlObject.toString().concat("&format=webp&w=500&dpr=2");
}
export const timezones = [
  {
    label: "Pacific/Midway (GMT-11:00)",
    value: "Pacific/Midway",
    name: "(GMT-11:00) Midway",
    utc: "-11:00",
  },
  {
    label: "Pacific/Niue (GMT-11:00)",
    value: "Pacific/Niue",
    name: "(GMT-11:00) Alofi",
    utc: "-11:00",
  },
  {
    label: "Pacific/Pago_Pago (GMT-11:00)",
    value: "Pacific/Pago_Pago",
    name: "(GMT-11:00) Pago Pago, Tāfuna, Ta`ū, Taulaga",
    utc: "-11:00",
  },
  {
    label: "America/Adak (GMT-10:00)",
    value: "America/Adak",
    name: "(GMT-10:00) Adak",
    utc: "-10:00",
  },
  {
    label: "Pacific/Honolulu (GMT-10:00)",
    value: "Pacific/Honolulu",
    name: "(GMT-10:00) Honolulu, East Honolulu, Pearl City, Hilo, Kailua",
    utc: "-10:00",
  },
  {
    label: "Pacific/Rarotonga (GMT-10:00)",
    value: "Pacific/Rarotonga",
    name: "(GMT-10:00) Avarua",
    utc: "-10:00",
  },
  {
    label: "Pacific/Tahiti (GMT-10:00)",
    value: "Pacific/Tahiti",
    name: "(GMT-10:00) Faaa, Papeete, Punaauia, Pirae, Mahina",
    utc: "-10:00",
  },
  {
    label: "Pacific/Marquesas (GMT-09:30)",
    value: "Pacific/Marquesas",
    name: "(GMT-09:30) Taiohae",
    utc: "-09:30",
  },
  {
    label: "America/Anchorage (GMT-09:00)",
    value: "America/Anchorage",
    name: "(GMT-09:00) Anchorage, Fairbanks, Eagle River, Badger, Knik-Fairview",
    utc: "-09:00",
  },
  {
    label: "America/Juneau (GMT-09:00)",
    value: "America/Juneau",
    name: "(GMT-09:00) Juneau",
    utc: "-09:00",
  },
  {
    label: "America/Metlakatla (GMT-09:00)",
    value: "America/Metlakatla",
    name: "(GMT-09:00) Metlakatla",
    utc: "-09:00",
  },
  {
    label: "America/Nome (GMT-09:00)",
    value: "America/Nome",
    name: "(GMT-09:00) Nome",
    utc: "-09:00",
  },
  {
    label: "America/Sitka (GMT-09:00)",
    value: "America/Sitka",
    name: "(GMT-09:00) Sitka, Ketchikan",
    utc: "-09:00",
  },
  {
    label: "America/Yakutat (GMT-09:00)",
    value: "America/Yakutat",
    name: "(GMT-09:00) Yakutat",
    utc: "-09:00",
  },
  {
    label: "Pacific/Gambier (GMT-09:00)",
    value: "Pacific/Gambier",
    name: "(GMT-09:00) Gambier",
    utc: "-09:00",
  },
  {
    label: "America/Los_Angeles (GMT-08:00)",
    value: "America/Los_Angeles",
    name: "(GMT-08:00) Los Angeles, San Diego, San Jose, San Francisco, Seattle",
    utc: "-08:00",
  },
  {
    label: "America/Tijuana (GMT-08:00)",
    value: "America/Tijuana",
    name: "(GMT-08:00) Tijuana, Mexicali, Ensenada, Rosarito, Tecate",
    utc: "-08:00",
  },
  {
    label: "America/Vancouver (GMT-08:00)",
    value: "America/Vancouver",
    name: "(GMT-08:00) Vancouver, Surrey, Okanagan, Victoria, Burnaby",
    utc: "-08:00",
  },
  {
    label: "Pacific/Pitcairn (GMT-08:00)",
    value: "Pacific/Pitcairn",
    name: "(GMT-08:00) Adamstown",
    utc: "-08:00",
  },
  {
    label: "America/Boise (GMT-07:00)",
    value: "America/Boise",
    name: "(GMT-07:00) Boise, Meridian, Nampa, Idaho Falls, Pocatello",
    utc: "-07:00",
  },
  {
    label: "America/Cambridge_Bay (GMT-07:00)",
    value: "America/Cambridge_Bay",
    name: "(GMT-07:00) Cambridge Bay",
    utc: "-07:00",
  },
  {
    label: "America/Chihuahua (GMT-07:00)",
    value: "America/Chihuahua",
    name: "(GMT-07:00) Chihuahua, Ciudad Delicias, Cuauhtémoc, Parral, Nuevo Casas Grandes",
    utc: "-07:00",
  },
  {
    label: "America/Creston (GMT-07:00)",
    value: "America/Creston",
    name: "(GMT-07:00) Creston",
    utc: "-07:00",
  },
  {
    label: "America/Dawson (GMT-07:00)",
    value: "America/Dawson",
    name: "(GMT-07:00) Dawson",
    utc: "-07:00",
  },
  {
    label: "America/Dawson_Creek (GMT-07:00)",
    value: "America/Dawson_Creek",
    name: "(GMT-07:00) Fort St. John, Dawson Creek",
    utc: "-07:00",
  },
  {
    label: "America/Denver (GMT-07:00)",
    value: "America/Denver",
    name: "(GMT-07:00) Denver, El Paso, Albuquerque, Colorado Springs, Aurora",
    utc: "-07:00",
  },
  {
    label: "America/Edmonton (GMT-07:00)",
    value: "America/Edmonton",
    name: "(GMT-07:00) Calgary, Edmonton, Fort McMurray, Red Deer, Lethbridge",
    utc: "-07:00",
  },
  {
    label: "America/Fort_Nelson (GMT-07:00)",
    value: "America/Fort_Nelson",
    name: "(GMT-07:00) Fort Nelson",
    utc: "-07:00",
  },
  {
    label: "America/Hermosillo (GMT-07:00)",
    value: "America/Hermosillo",
    name: "(GMT-07:00) Hermosillo, Ciudad Obregón, Nogales, San Luis Río Colorado, Navojoa",
    utc: "-07:00",
  },
  {
    label: "America/Inuvik (GMT-07:00)",
    value: "America/Inuvik",
    name: "(GMT-07:00) Inuvik",
    utc: "-07:00",
  },
  {
    label: "America/Mazatlan (GMT-07:00)",
    value: "America/Mazatlan",
    name: "(GMT-07:00) Culiacán, Mazatlán, Tepic, Los Mochis, La Paz",
    utc: "-07:00",
  },
  {
    label: "America/Ojinaga (GMT-07:00)",
    value: "America/Ojinaga",
    name: "(GMT-07:00) Ciudad Juárez, Manuel Ojinaga, Ojinaga",
    utc: "-07:00",
  },
  {
    label: "America/Phoenix (GMT-07:00)",
    value: "America/Phoenix",
    name: "(GMT-07:00) Phoenix, Tucson, Mesa, Chandler, Gilbert",
    utc: "-07:00",
  },
  {
    label: "America/Whitehorse (GMT-07:00)",
    value: "America/Whitehorse",
    name: "(GMT-07:00) Whitehorse",
    utc: "-07:00",
  },
  {
    label: "America/Yellowknife (GMT-07:00)",
    value: "America/Yellowknife",
    name: "(GMT-07:00) Yellowknife",
    utc: "-07:00",
  },
  {
    label: "America/Bahia_Banderas (GMT-06:00)",
    value: "America/Bahia_Banderas",
    name: "(GMT-06:00) Mezcales, San Vicente, Bucerías, Valle de Banderas",
    utc: "-06:00",
  },
  {
    label: "America/Belize (GMT-06:00)",
    value: "America/Belize",
    name: "(GMT-06:00) Belize City, San Ignacio, Orange Walk, Belmopan, Dangriga",
    utc: "-06:00",
  },
  {
    label: "America/Chicago (GMT-06:00)",
    value: "America/Chicago",
    name: "(GMT-06:00) Chicago, Houston, San Antonio, Dallas, Austin",
    utc: "-06:00",
  },
  {
    label: "America/Costa_Rica (GMT-06:00)",
    value: "America/Costa_Rica",
    name: "(GMT-06:00) San José, Limón, San Francisco, Alajuela, Liberia",
    utc: "-06:00",
  },
  {
    label: "America/El_Salvador (GMT-06:00)",
    value: "America/El_Salvador",
    name: "(GMT-06:00) San Salvador, Soyapango, Santa Ana, San Miguel, Mejicanos",
    utc: "-06:00",
  },
  {
    label: "America/Guatemala (GMT-06:00)",
    value: "America/Guatemala",
    name: "(GMT-06:00) Guatemala City, Mixco, Villa Nueva, Petapa, San Juan Sacatepéquez",
    utc: "-06:00",
  },
  {
    label: "America/Indiana/Knox (GMT-06:00)",
    value: "America/Indiana/Knox",
    name: "(GMT-06:00) Knox",
    utc: "-06:00",
  },
  {
    label: "America/Indiana/Tell_City (GMT-06:00)",
    value: "America/Indiana/Tell_City",
    name: "(GMT-06:00) Tell City",
    utc: "-06:00",
  },
  {
    label: "America/Managua (GMT-06:00)",
    value: "America/Managua",
    name: "(GMT-06:00) Managua, León, Masaya, Chinandega, Matagalpa",
    utc: "-06:00",
  },
  {
    label: "America/Matamoros (GMT-06:00)",
    value: "America/Matamoros",
    name: "(GMT-06:00) Reynosa, Heroica Matamoros, Nuevo Laredo, Piedras Negras, Ciudad Acuña",
    utc: "-06:00",
  },
  {
    label: "America/Menominee (GMT-06:00)",
    value: "America/Menominee",
    name: "(GMT-06:00) Menominee, Iron Mountain, Kingsford, Ironwood, Iron River",
    utc: "-06:00",
  },
  {
    label: "America/Merida (GMT-06:00)",
    value: "America/Merida",
    name: "(GMT-06:00) Mérida, Campeche, Ciudad del Carmen, Kanasín, Valladolid",
    utc: "-06:00",
  },
  {
    label: "America/Mexico_City (GMT-06:00)",
    value: "America/Mexico_City",
    name: "(GMT-06:00) Mexico City, Iztapalapa, Ecatepec de Morelos, Guadalajara, Puebla",
    utc: "-06:00",
  },
  {
    label: "America/Monterrey (GMT-06:00)",
    value: "America/Monterrey",
    name: "(GMT-06:00) Monterrey, Saltillo, Guadalupe, Torreón, Victoria de Durango",
    utc: "-06:00",
  },
  {
    label: "America/North_Dakota/Beulah (GMT-06:00)",
    value: "America/North_Dakota/Beulah",
    name: "(GMT-06:00) Beulah",
    utc: "-06:00",
  },
  {
    label: "America/North_Dakota/Center (GMT-06:00)",
    value: "America/North_Dakota/Center",
    name: "(GMT-06:00) Center",
    utc: "-06:00",
  },
  {
    label: "America/North_Dakota/New_Salem (GMT-06:00)",
    value: "America/North_Dakota/New_Salem",
    name: "(GMT-06:00) Mandan",
    utc: "-06:00",
  },
  {
    label: "America/Rainy_River (GMT-06:00)",
    value: "America/Rainy_River",
    name: "(GMT-06:00) Rainy River",
    utc: "-06:00",
  },
  {
    label: "America/Rankin_Inlet (GMT-06:00)",
    value: "America/Rankin_Inlet",
    name: "(GMT-06:00) Rankin Inlet",
    utc: "-06:00",
  },
  {
    label: "America/Regina (GMT-06:00)",
    value: "America/Regina",
    name: "(GMT-06:00) Saskatoon, Regina, Prince Albert, Moose Jaw, North Battleford",
    utc: "-06:00",
  },
  {
    label: "America/Resolute (GMT-06:00)",
    value: "America/Resolute",
    name: "(GMT-06:00) Resolute",
    utc: "-06:00",
  },
  {
    label: "America/Swift_Current (GMT-06:00)",
    value: "America/Swift_Current",
    name: "(GMT-06:00) Swift Current",
    utc: "-06:00",
  },
  {
    label: "America/Tegucigalpa (GMT-06:00)",
    value: "America/Tegucigalpa",
    name: "(GMT-06:00) Tegucigalpa, San Pedro Sula, Choloma, La Ceiba, El Progreso",
    utc: "-06:00",
  },
  {
    label: "America/Winnipeg (GMT-06:00)",
    value: "America/Winnipeg",
    name: "(GMT-06:00) Winnipeg, Brandon, Kenora, Portage la Prairie, Thompson",
    utc: "-06:00",
  },
  {
    label: "Pacific/Easter (GMT-06:00)",
    value: "Pacific/Easter",
    name: "(GMT-06:00) Easter",
    utc: "-06:00",
  },
  {
    label: "Pacific/Galapagos (GMT-06:00)",
    value: "Pacific/Galapagos",
    name: "(GMT-06:00) Puerto Ayora, Puerto Baquerizo Moreno",
    utc: "-06:00",
  },
  {
    label: "America/Atikokan (GMT-05:00)",
    value: "America/Atikokan",
    name: "(GMT-05:00) Atikokan",
    utc: "-05:00",
  },
  {
    label: "America/Bogota (GMT-05:00)",
    value: "America/Bogota",
    name: "(GMT-05:00) Bogotá, Cali, Medellín, Barranquilla, Cartagena",
    utc: "-05:00",
  },
  {
    label: "America/Cancun (GMT-05:00)",
    value: "America/Cancun",
    name: "(GMT-05:00) Cancún, Chetumal, Playa del Carmen, Cozumel, Felipe Carrillo Puerto",
    utc: "-05:00",
  },
  {
    label: "America/Cayman (GMT-05:00)",
    value: "America/Cayman",
    name: "(GMT-05:00) George Town, West Bay, Bodden Town, East End, North Side",
    utc: "-05:00",
  },
  {
    label: "America/Detroit (GMT-05:00)",
    value: "America/Detroit",
    name: "(GMT-05:00) Detroit, Grand Rapids, Warren, Sterling Heights, Ann Arbor",
    utc: "-05:00",
  },
  {
    label: "America/Eirunepe (GMT-05:00)",
    value: "America/Eirunepe",
    name: "(GMT-05:00) Eirunepé, Benjamin Constant, Envira",
    utc: "-05:00",
  },
  {
    label: "America/Grand_Turk (GMT-05:00)",
    value: "America/Grand_Turk",
    name: "(GMT-05:00) Cockburn Town",
    utc: "-05:00",
  },
  {
    label: "America/Guayaquil (GMT-05:00)",
    value: "America/Guayaquil",
    name: "(GMT-05:00) Guayaquil, Quito, Cuenca, Santo Domingo de los Colorados, Machala",
    utc: "-05:00",
  },
  {
    label: "America/Havana (GMT-05:00)",
    value: "America/Havana",
    name: "(GMT-05:00) Havana, Santiago de Cuba, Camagüey, Holguín, Guantánamo",
    utc: "-05:00",
  },
  {
    label: "America/Indiana/Indianapolis (GMT-05:00)",
    value: "America/Indiana/Indianapolis",
    name: "(GMT-05:00) Indianapolis, Fort Wayne, South Bend, Carmel, Bloomington",
    utc: "-05:00",
  },
  {
    label: "America/Indiana/Marengo (GMT-05:00)",
    value: "America/Indiana/Marengo",
    name: "(GMT-05:00) Marengo",
    utc: "-05:00",
  },
  {
    label: "America/Indiana/Petersburg (GMT-05:00)",
    value: "America/Indiana/Petersburg",
    name: "(GMT-05:00) Petersburg",
    utc: "-05:00",
  },
  {
    label: "America/Indiana/Vevay (GMT-05:00)",
    value: "America/Indiana/Vevay",
    name: "(GMT-05:00) Vevay",
    utc: "-05:00",
  },
  {
    label: "America/Indiana/Vincennes (GMT-05:00)",
    value: "America/Indiana/Vincennes",
    name: "(GMT-05:00) Vincennes, Jasper, Washington, Huntingburg",
    utc: "-05:00",
  },
  {
    label: "America/Indiana/Winamac (GMT-05:00)",
    value: "America/Indiana/Winamac",
    name: "(GMT-05:00) Winamac",
    utc: "-05:00",
  },
  {
    label: "America/Iqaluit (GMT-05:00)",
    value: "America/Iqaluit",
    name: "(GMT-05:00) Iqaluit",
    utc: "-05:00",
  },
  {
    label: "America/Jamaica (GMT-05:00)",
    value: "America/Jamaica",
    name: "(GMT-05:00) Kingston, New Kingston, Spanish Town, Portmore, Montego Bay",
    utc: "-05:00",
  },
  {
    label: "America/Kentucky/Louisville (GMT-05:00)",
    value: "America/Kentucky/Louisville",
    name: "(GMT-05:00) Louisville, Jeffersonville, New Albany, Jeffersontown, Pleasure Ridge Park",
    utc: "-05:00",
  },
  {
    label: "America/Kentucky/Monticello (GMT-05:00)",
    value: "America/Kentucky/Monticello",
    name: "(GMT-05:00) Monticello",
    utc: "-05:00",
  },
  {
    label: "America/Lima (GMT-05:00)",
    value: "America/Lima",
    name: "(GMT-05:00) Lima, Arequipa, Callao, Trujillo, Chiclayo",
    utc: "-05:00",
  },
  {
    label: "America/Nassau (GMT-05:00)",
    value: "America/Nassau",
    name: "(GMT-05:00) Nassau, Lucaya, Freeport, West End, Cooper’s Town",
    utc: "-05:00",
  },
  {
    label: "America/New_York (GMT-05:00)",
    value: "America/New_York",
    name: "(GMT-05:00) New York City, Brooklyn, Queens, Philadelphia, Manhattan",
    utc: "-05:00",
  },
  {
    label: "America/Nipigon (GMT-05:00)",
    value: "America/Nipigon",
    name: "(GMT-05:00) Nipigon",
    utc: "-05:00",
  },
  {
    label: "America/Panama (GMT-05:00)",
    value: "America/Panama",
    name: "(GMT-05:00) Panamá, San Miguelito, Juan Díaz, David, Arraiján",
    utc: "-05:00",
  },
  {
    label: "America/Pangnirtung (GMT-05:00)",
    value: "America/Pangnirtung",
    name: "(GMT-05:00) Pangnirtung",
    utc: "-05:00",
  },
  {
    label: "America/Port-au-Prince (GMT-05:00)",
    value: "America/Port-au-Prince",
    name: "(GMT-05:00) Port-au-Prince, Carrefour, Delmas 73, Pétionville, Port-de-Paix",
    utc: "-05:00",
  },
  {
    label: "America/Rio_Branco (GMT-05:00)",
    value: "America/Rio_Branco",
    name: "(GMT-05:00) Rio Branco, Cruzeiro do Sul, Sena Madureira, Tarauacá, Feijó",
    utc: "-05:00",
  },
  {
    label: "America/Thunder_Bay (GMT-05:00)",
    value: "America/Thunder_Bay",
    name: "(GMT-05:00) Thunder Bay",
    utc: "-05:00",
  },
  {
    label: "America/Toronto (GMT-05:00)",
    value: "America/Toronto",
    name: "(GMT-05:00) Toronto, Montréal, Ottawa, Mississauga, Québec",
    utc: "-05:00",
  },
  {
    label: "America/AnguillaSandy Hill (GMT-04:00)",
    value: "America/AnguillaSandy Hill",
    name: "(GMT-04:00) The Valley, Blowing Point Village, Sandy Ground Village, The Quarter, Sandy Hill",
    utc: "-04:00",
  },
  {
    label: "America/Antigua (GMT-04:00)",
    value: "America/Antigua",
    name: "(GMT-04:00) Saint John’s, Piggotts, Bolands, Codrington, Parham",
    utc: "-04:00",
  },
  {
    label: "America/Aruba (GMT-04:00)",
    value: "America/Aruba",
    name: "(GMT-04:00) Oranjestad, Tanki Leendert, San Nicolas, Santa Cruz, Paradera",
    utc: "-04:00",
  },
  {
    label: "America/Asuncion (GMT-04:00)",
    value: "America/Asuncion",
    name: "(GMT-04:00) Asunción, Ciudad del Este, San Lorenzo, Capiatá, Lambaré",
    utc: "-04:00",
  },
  {
    label: "America/Barbados (GMT-04:00)",
    value: "America/Barbados",
    name: "(GMT-04:00) Bridgetown, Speightstown, Oistins, Bathsheba, Holetown",
    utc: "-04:00",
  },
  {
    label: "America/Blanc-Sablon (GMT-04:00)",
    value: "America/Blanc-Sablon",
    name: "(GMT-04:00) Lévis",
    utc: "-04:00",
  },
  {
    label: "America/Boa_Vista (GMT-04:00)",
    value: "America/Boa_Vista",
    name: "(GMT-04:00) Boa Vista",
    utc: "-04:00",
  },
  {
    label: "America/Campo_Grande (GMT-04:00)",
    value: "America/Campo_Grande",
    name: "(GMT-04:00) Campo Grande, Dourados, Corumbá, Três Lagoas, Ponta Porã",
    utc: "-04:00",
  },
  {
    label: "America/Caracas (GMT-04:00)",
    value: "America/Caracas",
    name: "(GMT-04:00) Caracas, Maracaibo, Maracay, Valencia, Barquisimeto",
    utc: "-04:00",
  },
  {
    label: "America/Cuiaba (GMT-04:00)",
    value: "America/Cuiaba",
    name: "(GMT-04:00) Cuiabá, Várzea Grande, Rondonópolis, Sinop, Barra do Garças",
    utc: "-04:00",
  },
  {
    label: "America/Curacao (GMT-04:00)",
    value: "America/Curacao",
    name: "(GMT-04:00) Willemstad, Sint Michiel Liber",
    utc: "-04:00",
  },
  {
    label: "America/Dominica (GMT-04:00)",
    value: "America/Dominica",
    name: "(GMT-04:00) Roseau, Portsmouth, Berekua, Saint Joseph, Wesley",
    utc: "-04:00",
  },
  {
    label: "America/Glace_Bay (GMT-04:00)",
    value: "America/Glace_Bay",
    name: "(GMT-04:00) Sydney, Glace Bay, Sydney Mines",
    utc: "-04:00",
  },
  {
    label: "America/Goose_Bay (GMT-04:00)",
    value: "America/Goose_Bay",
    name: "(GMT-04:00) Labrador City, Happy Valley-Goose Bay",
    utc: "-04:00",
  },
  {
    label: "America/Grenada (GMT-04:00)",
    value: "America/Grenada",
    name: "(GMT-04:00) Saint George's, Gouyave, Grenville, Victoria, Saint David’s",
    utc: "-04:00",
  },
  {
    label: "America/Guadeloupe (GMT-04:00)",
    value: "America/Guadeloupe",
    name: "(GMT-04:00) Les Abymes, Baie-Mahault, Le Gosier, Petit-Bourg, Sainte-Anne",
    utc: "-04:00",
  },
  {
    label: "America/Guyana (GMT-04:00)",
    value: "America/Guyana",
    name: "(GMT-04:00) Georgetown, Linden, New Amsterdam, Anna Regina, Bartica",
    utc: "-04:00",
  },
  {
    label: "America/Halifax (GMT-04:00)",
    value: "America/Halifax",
    name: "(GMT-04:00) Halifax, Dartmouth, Charlottetown, Lower Sackville, Truro",
    utc: "-04:00",
  },
  {
    label: "America/Kralendijk (GMT-04:00)",
    value: "America/Kralendijk",
    name: "(GMT-04:00) Kralendijk, Oranjestad, The Bottom",
    utc: "-04:00",
  },
  {
    label: "America/La_Paz (GMT-04:00)",
    value: "America/La_Paz",
    name: "(GMT-04:00) Santa Cruz de la Sierra, Cochabamba, La Paz, Sucre, Oruro",
    utc: "-04:00",
  },
  {
    label: "America/Lower_Princes (GMT-04:00)",
    value: "America/Lower_Princes",
    name: "(GMT-04:00) Cul de Sac, Lower Prince’s Quarter, Koolbaai, Philipsburg",
    utc: "-04:00",
  },
  {
    label: "America/Manaus (GMT-04:00)",
    value: "America/Manaus",
    name: "(GMT-04:00) Manaus, Itacoatiara, Parintins, Manacapuru, Coari",
    utc: "-04:00",
  },
  {
    label: "America/Marigot (GMT-04:00)",
    value: "America/Marigot",
    name: "(GMT-04:00) Marigot",
    utc: "-04:00",
  },
  {
    label: "America/Martinique (GMT-04:00)",
    value: "America/Martinique",
    name: "(GMT-04:00) Fort-de-France, Le Lamentin, Le Robert, Sainte-Marie, Le François",
    utc: "-04:00",
  },
  {
    label: "America/Moncton (GMT-04:00)",
    value: "America/Moncton",
    name: "(GMT-04:00) Moncton, Saint John, Fredericton, Dieppe, Miramichi",
    utc: "-04:00",
  },
  {
    label: "America/Montserrat (GMT-04:00)",
    value: "America/Montserrat",
    name: "(GMT-04:00) Brades, Saint Peters, Plymouth",
    utc: "-04:00",
  },
  {
    label: "America/Porto_Velho (GMT-04:00)",
    value: "America/Porto_Velho",
    name: "(GMT-04:00) Porto Velho, Ji Paraná, Vilhena, Ariquemes, Cacoal",
    utc: "-04:00",
  },
  {
    label: "America/Port_of_Spain (GMT-04:00)",
    value: "America/Port_of_Spain",
    name: "(GMT-04:00) Chaguanas, Mon Repos, San Fernando, Port of Spain, Rio Claro",
    utc: "-04:00",
  },
  {
    label: "America/Puerto_Rico (GMT-04:00)",
    value: "America/Puerto_Rico",
    name: "(GMT-04:00) San Juan, Bayamón, Carolina, Ponce, Caguas",
    utc: "-04:00",
  },
  {
    label: "America/Santiago (GMT-04:00)",
    value: "America/Santiago",
    name: "(GMT-04:00) Santiago, Puente Alto, Antofagasta, Viña del Mar, Valparaíso",
    utc: "-04:00",
  },
  {
    label: "America/Santo_Domingo (GMT-04:00)",
    value: "America/Santo_Domingo",
    name: "(GMT-04:00) Santo Domingo, Santiago de los Caballeros, Santo Domingo Oeste, Santo Domingo Este, San Pedro de Macorís",
    utc: "-04:00",
  },
  {
    label: "America/St_Barthelemy (GMT-04:00)",
    value: "America/St_Barthelemy",
    name: "(GMT-04:00) Gustavia",
    utc: "-04:00",
  },
  {
    label: "America/St_Kitts (GMT-04:00)",
    value: "America/St_Kitts",
    name: "(GMT-04:00) Basseterre, Fig Tree, Market Shop, Saint Paul’s, Middle Island",
    utc: "-04:00",
  },
  {
    label: "America/St_Lucia (GMT-04:00)",
    value: "America/St_Lucia",
    name: "(GMT-04:00) Castries, Bisee, Vieux Fort, Micoud, Soufrière",
    utc: "-04:00",
  },
  {
    label: "America/St_Thomas (GMT-04:00)",
    value: "America/St_Thomas",
    name: "(GMT-04:00) Saint Croix, Charlotte Amalie, Cruz Bay",
    utc: "-04:00",
  },
  {
    label: "America/St_Vincent (GMT-04:00)",
    value: "America/St_Vincent",
    name: "(GMT-04:00) Kingstown, Kingstown Park, Georgetown, Barrouallie, Port Elizabeth",
    utc: "-04:00",
  },
  {
    label: "America/Thule (GMT-04:00)",
    value: "America/Thule",
    name: "(GMT-04:00) Thule",
    utc: "-04:00",
  },
  {
    label: "America/Tortola (GMT-04:00)",
    value: "America/Tortola",
    name: "(GMT-04:00) Road Town",
    utc: "-04:00",
  },
  {
    label: "Atlantic/Bermuda (GMT-04:00)",
    value: "Atlantic/Bermuda",
    name: "(GMT-04:00) Hamilton",
    utc: "-04:00",
  },
  {
    label: "America/St_Johns (GMT-03:30)",
    value: "America/St_Johns",
    name: "(GMT-03:30) St. John's, Mount Pearl, Corner Brook, Conception Bay South, Bay Roberts",
    utc: "-03:30",
  },
  {
    label: "America/Araguaina (GMT-03:00)",
    value: "America/Araguaina",
    name: "(GMT-03:00) Palmas, Araguaína, Gurupi, Miracema do Tocantins, Porto Franco",
    utc: "-03:00",
  },
  {
    label: "America/Argentina/Buenos_Aires (GMT-03:00)",
    value: "America/Argentina/Buenos_Aires",
    name: "(GMT-03:00) Buenos Aires, La Plata, Mar del Plata, Morón, Bahía Blanca",
    utc: "-03:00",
  },
  {
    label: "America/Argentina/Catamarca (GMT-03:00)",
    value: "America/Argentina/Catamarca",
    name: "(GMT-03:00) San Fernando del Valle de Catamarca, Trelew, Puerto Madryn, Esquel, Rawson",
    utc: "-03:00",
  },
  {
    label: "America/Argentina/Cordoba (GMT-03:00)",
    value: "America/Argentina/Cordoba",
    name: "(GMT-03:00) Córdoba, Rosario, Santa Fe, Resistencia, Santiago del Estero",
    utc: "-03:00",
  },
  {
    label: "America/Argentina/Jujuy (GMT-03:00)",
    value: "America/Argentina/Jujuy",
    name: "(GMT-03:00) San Salvador de Jujuy, San Pedro de Jujuy, Libertador General San Martín, Palpalá, La Quiaca",
    utc: "-03:00",
  },
  {
    label: "America/Argentina/La_Rioja (GMT-03:00)",
    value: "America/Argentina/La_Rioja",
    name: "(GMT-03:00) La Rioja, Chilecito, Arauco, Chamical",
    utc: "-03:00",
  },
  {
    label: "America/Argentina/Mendoza (GMT-03:00)",
    value: "America/Argentina/Mendoza",
    name: "(GMT-03:00) Mendoza, San Rafael, San Martín",
    utc: "-03:00",
  },
  {
    label: "America/Argentina/Rio_Gallegos (GMT-03:00)",
    value: "America/Argentina/Rio_Gallegos",
    name: "(GMT-03:00) Comodoro Rivadavia, Río Gallegos, Caleta Olivia, Pico Truncado, Puerto Deseado",
    utc: "-03:00",
  },
  {
    label: "America/Argentina/Salta (GMT-03:00)",
    value: "America/Argentina/Salta",
    name: "(GMT-03:00) Salta, Neuquén, Santa Rosa, San Carlos de Bariloche, Cipolletti",
    utc: "-03:00",
  },
  {
    label: "America/Argentina/San_Juan (GMT-03:00)",
    value: "America/Argentina/San_Juan",
    name: "(GMT-03:00) San Juan, Chimbas, Santa Lucía, Pocito, Caucete",
    utc: "-03:00",
  },
  {
    label: "America/Argentina/San_Luis (GMT-03:00)",
    value: "America/Argentina/San_Luis",
    name: "(GMT-03:00) San Luis, Villa Mercedes, La Punta, Merlo, Justo Daract",
    utc: "-03:00",
  },
  {
    label: "America/Argentina/Tucuman (GMT-03:00)",
    value: "America/Argentina/Tucuman",
    name: "(GMT-03:00) San Miguel de Tucumán, Yerba Buena, Tafí Viejo, Alderetes, Aguilares",
    utc: "-03:00",
  },
  {
    label: "America/Argentina/Ushuaia (GMT-03:00)",
    value: "America/Argentina/Ushuaia",
    name: "(GMT-03:00) Ushuaia, Río Grande",
    utc: "-03:00",
  },
  {
    label: "America/Bahia (GMT-03:00)",
    value: "America/Bahia",
    name: "(GMT-03:00) Salvador, Feira de Santana, Vitória da Conquista, Itabuna, Camaçari",
    utc: "-03:00",
  },
  {
    label: "America/Belem (GMT-03:00)",
    value: "America/Belem",
    name: "(GMT-03:00) Belém, Ananindeua, Macapá, Parauapebas, Marabá",
    utc: "-03:00",
  },
  {
    label: "America/Cayenne (GMT-03:00)",
    value: "America/Cayenne",
    name: "(GMT-03:00) Cayenne, Matoury, Saint-Laurent-du-Maroni, Kourou, Rémire-Montjoly",
    utc: "-03:00",
  },
  {
    label: "America/Fortaleza (GMT-03:00)",
    value: "America/Fortaleza",
    name: "(GMT-03:00) Fortaleza, São Luís, Natal, Teresina, João Pessoa",
    utc: "-03:00",
  },
  {
    label: "America/Godthab (GMT-03:00)",
    value: "America/Godthab",
    name: "(GMT-03:00) Nuuk, Sisimiut, Ilulissat, Qaqortoq, Aasiaat",
    utc: "-03:00",
  },
  {
    label: "America/Maceio (GMT-03:00)",
    value: "America/Maceio",
    name: "(GMT-03:00) Maceió, Aracaju, Arapiraca, Nossa Senhora do Socorro, São Cristóvão",
    utc: "-03:00",
  },
  {
    label: "America/Miquelon (GMT-03:00)",
    value: "America/Miquelon",
    name: "(GMT-03:00) Saint-Pierre, Miquelon",
    utc: "-03:00",
  },
  {
    label: "America/Montevideo (GMT-03:00)",
    value: "America/Montevideo",
    name: "(GMT-03:00) Montevideo, Salto, Paysandú, Las Piedras, Rivera",
    utc: "-03:00",
  },
  {
    label: "America/Paramaribo (GMT-03:00)",
    value: "America/Paramaribo",
    name: "(GMT-03:00) Paramaribo, Lelydorp, Brokopondo, Nieuw Nickerie, Moengo",
    utc: "-03:00",
  },
  {
    label: "America/Punta_Arenas (GMT-03:00)",
    value: "America/Punta_Arenas",
    name: "(GMT-03:00) Punta Arenas, Puerto Natales",
    utc: "-03:00",
  },
  {
    label: "America/Recife (GMT-03:00)",
    value: "America/Recife",
    name: "(GMT-03:00) Recife, Jaboatão, Jaboatão dos Guararapes, Olinda, Paulista",
    utc: "-03:00",
  },
  {
    label: "America/Santarem (GMT-03:00)",
    value: "America/Santarem",
    name: "(GMT-03:00) Santarém, Altamira, Itaituba, Oriximiná, Alenquer",
    utc: "-03:00",
  },
  {
    label: "America/Sao_Paulo (GMT-03:00)",
    value: "America/Sao_Paulo",
    name: "(GMT-03:00) São Paulo, Rio de Janeiro, Belo Horizonte, Brasília, Curitiba",
    utc: "-03:00",
  },
  {
    label: "Antarctica/Palmer (GMT-03:00)",
    value: "Antarctica/Palmer",
    name: "(GMT-03:00) Palmer",
    utc: "-03:00",
  },
  {
    label: "Antarctica/Rothera (GMT-03:00)",
    value: "Antarctica/Rothera",
    name: "(GMT-03:00) Rothera",
    utc: "-03:00",
  },
  {
    label: "Atlantic/Stanley (GMT-03:00)",
    value: "Atlantic/Stanley",
    name: "(GMT-03:00) Stanley",
    utc: "-03:00",
  },
  {
    label: "America/Noronha (GMT-02:00)",
    value: "America/Noronha",
    name: "(GMT-02:00) Itamaracá",
    utc: "-02:00",
  },
  {
    label: "Atlantic/South_Georgia (GMT-02:00)",
    value: "Atlantic/South_Georgia",
    name: "(GMT-02:00) Grytviken",
    utc: "-02:00",
  },
  {
    label: "America/Scoresbysund (GMT-01:00)",
    value: "America/Scoresbysund",
    name: "(GMT-01:00) Scoresbysund",
    utc: "-01:00",
  },
  {
    label: "Atlantic/Azores (GMT-01:00)",
    value: "Atlantic/Azores",
    name: "(GMT-01:00) Ponta Delgada, Lagoa, Angra do Heroísmo, Rosto de Cão, Rabo de Peixe",
    utc: "-01:00",
  },
  {
    label: "Atlantic/Cape_Verde (GMT-01:00)",
    value: "Atlantic/Cape_Verde",
    name: "(GMT-01:00) Praia, Mindelo, Santa Maria, Cova Figueira, Santa Cruz",
    utc: "-01:00",
  },
  {
    label: "Africa/Abidjan (GMT+00:00)",
    value: "Africa/Abidjan",
    name: "(GMT+00:00) Abidjan, Abobo, Bouaké, Daloa, San-Pédro",
    utc: "+00:00",
  },
  {
    label: "Africa/Accra (GMT+00:00)",
    value: "Africa/Accra",
    name: "(GMT+00:00) Accra, Kumasi, Tamale, Takoradi, Atsiaman",
    utc: "+00:00",
  },
  {
    label: "Africa/Bamako (GMT+00:00)",
    value: "Africa/Bamako",
    name: "(GMT+00:00) Bamako, Sikasso, Mopti, Koutiala, Ségou",
    utc: "+00:00",
  },
  {
    label: "Africa/Banjul (GMT+00:00)",
    value: "Africa/Banjul",
    name: "(GMT+00:00) Serekunda, Brikama, Bakau, Banjul, Farafenni",
    utc: "+00:00",
  },
  {
    label: "Africa/Bissau (GMT+00:00)",
    value: "Africa/Bissau",
    name: "(GMT+00:00) Bissau, Bafatá, Gabú, Bissorã, Bolama",
    utc: "+00:00",
  },
  {
    label: "Africa/Casablanca (GMT+00:00)",
    value: "Africa/Casablanca",
    name: "(GMT+00:00) Casablanca, Rabat, Fès, Sale, Marrakesh",
    utc: "+00:00",
  },
  {
    label: "Africa/Conakry (GMT+00:00)",
    value: "Africa/Conakry",
    name: "(GMT+00:00) Camayenne, Conakry, Nzérékoré, Kindia, Kankan",
    utc: "+00:00",
  },
  {
    label: "Africa/Dakar (GMT+00:00)",
    value: "Africa/Dakar",
    name: "(GMT+00:00) Dakar, Pikine, Touba, Thiès, Thiès Nones",
    utc: "+00:00",
  },
  {
    label: "Africa/El_Aaiun (GMT+00:00)",
    value: "Africa/El_Aaiun",
    name: "(GMT+00:00) Laayoune, Dakhla, Laayoune Plage",
    utc: "+00:00",
  },
  {
    label: "Africa/Freetown (GMT+00:00)",
    value: "Africa/Freetown",
    name: "(GMT+00:00) Freetown, Bo, Kenema, Koidu, Makeni",
    utc: "+00:00",
  },
  {
    label: "Africa/Lome (GMT+00:00)",
    value: "Africa/Lome",
    name: "(GMT+00:00) Lomé, Sokodé, Kara, Atakpamé, Kpalimé",
    utc: "+00:00",
  },
  {
    label: "Africa/Monrovia (GMT+00:00)",
    value: "Africa/Monrovia",
    name: "(GMT+00:00) Monrovia, Gbarnga, Kakata, Bensonville, Harper",
    utc: "+00:00",
  },
  {
    label: "Africa/Nouakchott (GMT+00:00)",
    value: "Africa/Nouakchott",
    name: "(GMT+00:00) Nouakchott, Nouadhibou, Néma, Kaédi, Rosso",
    utc: "+00:00",
  },
  {
    label: "Africa/Ouagadougou (GMT+00:00)",
    value: "Africa/Ouagadougou",
    name: "(GMT+00:00) Ouagadougou, Bobo-Dioulasso, Koudougou, Ouahigouya, Banfora",
    utc: "+00:00",
  },
  {
    label: "Africa/Sao_Tome (GMT+00:00)",
    value: "Africa/Sao_Tome",
    name: "(GMT+00:00) São Tomé, Santo António",
    utc: "+00:00",
  },
  {
    label: "America/Danmarkshavn (GMT+00:00)",
    value: "America/Danmarkshavn",
    name: "(GMT+00:00) Danmarkshavn",
    utc: "+00:00",
  },
  {
    label: "Antarctica/Troll (GMT+00:00)",
    value: "Antarctica/Troll",
    name: "(GMT+00:00) Troll",
    utc: "+00:00",
  },
  {
    label: "Atlantic/Canary (GMT+00:00)",
    value: "Atlantic/Canary",
    name: "(GMT+00:00) Las Palmas de Gran Canaria, Santa Cruz de Tenerife, La Laguna, Telde, Arona",
    utc: "+00:00",
  },
  {
    label: "Atlantic/Faroe (GMT+00:00)",
    value: "Atlantic/Faroe",
    name: "(GMT+00:00) Tórshavn, Klaksvík, Fuglafjørður, Tvøroyri, Miðvágur",
    utc: "+00:00",
  },
  {
    label: "Atlantic/Madeira (GMT+00:00)",
    value: "Atlantic/Madeira",
    name: "(GMT+00:00) Funchal, Câmara de Lobos, São Martinho, Caniço, Machico",
    utc: "+00:00",
  },
  {
    label: "Atlantic/Reykjavik (GMT+00:00)",
    value: "Atlantic/Reykjavik",
    name: "(GMT+00:00) Reykjavík, Kópavogur, Hafnarfjörður, Akureyri, Garðabær",
    utc: "+00:00",
  },
  {
    label: "Atlantic/St_Helena (GMT+00:00)",
    value: "Atlantic/St_Helena",
    name: "(GMT+00:00) Jamestown, Georgetown, Edinburgh of the Seven Seas",
    utc: "+00:00",
  },
  {
    label: "Europe/Dublin (GMT+00:00)",
    value: "Europe/Dublin",
    name: "(GMT+00:00) Dublin, Cork, Luimneach, Gaillimh, Tallaght",
    utc: "+00:00",
  },
  {
    label: "Europe/Guernsey (GMT+00:00)",
    value: "Europe/Guernsey",
    name: "(GMT+00:00) Saint Peter Port, St Martin, Saint Sampson, St Anne, Saint Saviour",
    utc: "+00:00",
  },
  {
    label: "Europe/Isle_of_Man (GMT+00:00)",
    value: "Europe/Isle_of_Man",
    name: "(GMT+00:00) Douglas, Ramsey, Peel, Port Erin, Castletown",
    utc: "+00:00",
  },
  {
    label: "Europe/Jersey (GMT+00:00)",
    value: "Europe/Jersey",
    name: "(GMT+00:00) Saint Helier, Le Hocq",
    utc: "+00:00",
  },
  {
    label: "Europe/Lisbon (GMT+00:00)",
    value: "Europe/Lisbon",
    name: "(GMT+00:00) Lisbon, Porto, Amadora, Braga, Setúbal",
    utc: "+00:00",
  },
  {
    label: "Europe/London (GMT+00:00)",
    value: "Europe/London",
    name: "(GMT+00:00) London, Birmingham, Liverpool, Sheffield, Bristol",
    utc: "+00:00",
  },
  {
    label: "Africa/Algiers (GMT+01:00)",
    value: "Africa/Algiers",
    name: "(GMT+01:00) Algiers, Boumerdas, Oran, Tébessa, Constantine",
    utc: "+01:00",
  },
  {
    label: "Africa/Bangui (GMT+01:00)",
    value: "Africa/Bangui",
    name: "(GMT+01:00) Bangui, Bimbo, Mbaïki, Berbérati, Kaga Bandoro",
    utc: "+01:00",
  },
  {
    label: "Africa/Brazzaville (GMT+01:00)",
    value: "Africa/Brazzaville",
    name: "(GMT+01:00) Brazzaville, Pointe-Noire, Dolisie, Kayes, Owando",
    utc: "+01:00",
  },
  {
    label: "Africa/Ceuta (GMT+01:00)",
    value: "Africa/Ceuta",
    name: "(GMT+01:00) Ceuta, Melilla",
    utc: "+01:00",
  },
  {
    label: "Africa/Douala (GMT+01:00)",
    value: "Africa/Douala",
    name: "(GMT+01:00) Douala, Yaoundé, Garoua, Kousséri, Bamenda",
    utc: "+01:00",
  },
  {
    label: "Africa/Kinshasa (GMT+01:00)",
    value: "Africa/Kinshasa",
    name: "(GMT+01:00) Kinshasa, Masina, Kikwit, Mbandaka, Matadi",
    utc: "+01:00",
  },
  {
    label: "Africa/Lagos (GMT+01:00)",
    value: "Africa/Lagos",
    name: "(GMT+01:00) Lagos, Kano, Ibadan, Kaduna, Port Harcourt",
    utc: "+01:00",
  },
  {
    label: "Africa/Libreville (GMT+01:00)",
    value: "Africa/Libreville",
    name: "(GMT+01:00) Libreville, Port-Gentil, Franceville, Oyem, Moanda",
    utc: "+01:00",
  },
  {
    label: "Africa/Luanda (GMT+01:00)",
    value: "Africa/Luanda",
    name: "(GMT+01:00) Luanda, N’dalatando, Huambo, Lobito, Benguela",
    utc: "+01:00",
  },
  {
    label: "Africa/Malabo (GMT+01:00)",
    value: "Africa/Malabo",
    name: "(GMT+01:00) Bata, Malabo, Ebebiyin, Aconibe, Añisoc",
    utc: "+01:00",
  },
  {
    label: "Africa/Ndjamena (GMT+01:00)",
    value: "Africa/Ndjamena",
    name: "(GMT+01:00) N'Djamena, Moundou, Sarh, Abéché, Kelo",
    utc: "+01:00",
  },
  {
    label: "Africa/Niamey (GMT+01:00)",
    value: "Africa/Niamey",
    name: "(GMT+01:00) Niamey, Zinder, Maradi, Agadez, Alaghsas",
    utc: "+01:00",
  },
  {
    label: "Africa/Porto-Novo (GMT+01:00)",
    value: "Africa/Porto-Novo",
    name: "(GMT+01:00) Cotonou, Abomey-Calavi, Djougou, Porto-Novo, Parakou",
    utc: "+01:00",
  },
  {
    label: "Africa/Tunis (GMT+01:00)",
    value: "Africa/Tunis",
    name: "(GMT+01:00) Tunis, Sfax, Sousse, Kairouan, Bizerte",
    utc: "+01:00",
  },
  {
    label: "Africa/Windhoek (GMT+01:00)",
    value: "Africa/Windhoek",
    name: "(GMT+01:00) Windhoek, Rundu, Walvis Bay, Oshakati, Swakopmund",
    utc: "+01:00",
  },
  {
    label: "Arctic/Longyearbyen (GMT+01:00)",
    value: "Arctic/Longyearbyen",
    name: "(GMT+01:00) Longyearbyen, Olonkinbyen",
    utc: "+01:00",
  },
  {
    label: "Europe/Amsterdam (GMT+01:00)",
    value: "Europe/Amsterdam",
    name: "(GMT+01:00) Amsterdam, Rotterdam, The Hague, Utrecht, Eindhoven",
    utc: "+01:00",
  },
  {
    label: "Europe/Andorra (GMT+01:00)",
    value: "Europe/Andorra",
    name: "(GMT+01:00) Andorra la Vella, les Escaldes, Encamp, Sant Julià de Lòria, la Massana",
    utc: "+01:00",
  },
  {
    label: "Europe/Belgrade (GMT+01:00)",
    value: "Europe/Belgrade",
    name: "(GMT+01:00) Belgrade, Pristina, Niš, Novi Sad, Prizren",
    utc: "+01:00",
  },
  {
    label: "Europe/Berlin (GMT+01:00)",
    value: "Europe/Berlin",
    name: "(GMT+01:00) Berlin, Hamburg, Munich, Köln, Frankfurt am Main",
    utc: "+01:00",
  },
  {
    label: "Europe/Bratislava (GMT+01:00)",
    value: "Europe/Bratislava",
    name: "(GMT+01:00) Bratislava, Košice, Prešov, Nitra, Žilina",
    utc: "+01:00",
  },
  {
    label: "Europe/Brussels (GMT+01:00)",
    value: "Europe/Brussels",
    name: "(GMT+01:00) Brussels, Antwerpen, Gent, Charleroi, Liège",
    utc: "+01:00",
  },
  {
    label: "Europe/Budapest (GMT+01:00)",
    value: "Europe/Budapest",
    name: "(GMT+01:00) Budapest, Debrecen, Miskolc, Szeged, Pécs",
    utc: "+01:00",
  },
  {
    label: "Europe/Copenhagen (GMT+01:00)",
    value: "Europe/Copenhagen",
    name: "(GMT+01:00) Copenhagen, Århus, Odense, Aalborg, Frederiksberg",
    utc: "+01:00",
  },
  {
    label: "Europe/Gibraltar (GMT+01:00)",
    value: "Europe/Gibraltar",
    name: "(GMT+01:00) Gibraltar",
    utc: "+01:00",
  },
  {
    label: "Europe/Ljubljana (GMT+01:00)",
    value: "Europe/Ljubljana",
    name: "(GMT+01:00) Ljubljana, Maribor, Celje, Kranj, Velenje",
    utc: "+01:00",
  },
  {
    label: "Europe/Luxembourg (GMT+01:00)",
    value: "Europe/Luxembourg",
    name: "(GMT+01:00) Luxembourg, Esch-sur-Alzette, Dudelange, Schifflange, Bettembourg",
    utc: "+01:00",
  },
  {
    label: "Europe/Madrid (GMT+01:00)",
    value: "Europe/Madrid",
    name: "(GMT+01:00) Madrid, Barcelona, Valencia, Sevilla, Zaragoza",
    utc: "+01:00",
  },
  {
    label: "Europe/Malta (GMT+01:00)",
    value: "Europe/Malta",
    name: "(GMT+01:00) Birkirkara, Qormi, Mosta, Żabbar, San Pawl il-Baħar",
    utc: "+01:00",
  },
  {
    label: "Europe/Monaco (GMT+01:00)",
    value: "Europe/Monaco",
    name: "(GMT+01:00) Monaco, Monte-Carlo, La Condamine",
    utc: "+01:00",
  },
  {
    label: "Europe/Oslo (GMT+01:00)",
    value: "Europe/Oslo",
    name: "(GMT+01:00) Oslo, Bergen, Trondheim, Stavanger, Drammen",
    utc: "+01:00",
  },
  {
    label: "Europe/Paris (GMT+01:00)",
    value: "Europe/Paris",
    name: "(GMT+01:00) Paris, Marseille, Lyon, Toulouse, Nice",
    utc: "+01:00",
  },
  {
    label: "Europe/Podgorica (GMT+01:00)",
    value: "Europe/Podgorica",
    name: "(GMT+01:00) Podgorica, Nikšić, Herceg Novi, Pljevlja, Budva",
    utc: "+01:00",
  },
  {
    label: "Europe/Prague (GMT+01:00)",
    value: "Europe/Prague",
    name: "(GMT+01:00) Prague, Brno, Ostrava, Pilsen, Olomouc",
    utc: "+01:00",
  },
  {
    label: "Europe/Rome (GMT+01:00)",
    value: "Europe/Rome",
    name: "(GMT+01:00) Rome, Milan, Naples, Turin, Palermo",
    utc: "+01:00",
  },
  {
    label: "Europe/San_Marino (GMT+01:00)",
    value: "Europe/San_Marino",
    name: "(GMT+01:00) Serravalle, Borgo Maggiore, San Marino, Domagnano, Fiorentino",
    utc: "+01:00",
  },
  {
    label: "Europe/Sarajevo (GMT+01:00)",
    value: "Europe/Sarajevo",
    name: "(GMT+01:00) Sarajevo, Banja Luka, Zenica, Tuzla, Mostar",
    utc: "+01:00",
  },
  {
    label: "Europe/Skopje (GMT+01:00)",
    value: "Europe/Skopje",
    name: "(GMT+01:00) Skopje, Bitola, Kumanovo, Prilep, Tetovo",
    utc: "+01:00",
  },
  {
    label: "Europe/Stockholm (GMT+01:00)",
    value: "Europe/Stockholm",
    name: "(GMT+01:00) Stockholm, Göteborg, Malmö, Uppsala, Sollentuna",
    utc: "+01:00",
  },
  {
    label: "Europe/Tirane (GMT+01:00)",
    value: "Europe/Tirane",
    name: "(GMT+01:00) Tirana, Durrës, Elbasan, Vlorë, Shkodër",
    utc: "+01:00",
  },
  {
    label: "Europe/Vaduz (GMT+01:00)",
    value: "Europe/Vaduz",
    name: "(GMT+01:00) Schaan, Vaduz, Triesen, Balzers, Eschen",
    utc: "+01:00",
  },
  {
    label: "Europe/Vatican (GMT+01:00)",
    value: "Europe/Vatican",
    name: "(GMT+01:00) Vatican City",
    utc: "+01:00",
  },
  {
    label: "Europe/Vienna (GMT+01:00)",
    value: "Europe/Vienna",
    name: "(GMT+01:00) Vienna, Graz, Linz, Favoriten, Donaustadt",
    utc: "+01:00",
  },
  {
    label: "Europe/Warsaw (GMT+01:00)",
    value: "Europe/Warsaw",
    name: "(GMT+01:00) Warsaw, Łódź, Kraków, Wrocław, Poznań",
    utc: "+01:00",
  },
  {
    label: "Europe/Zagreb (GMT+01:00)",
    value: "Europe/Zagreb",
    name: "(GMT+01:00) Zagreb, Split, Rijeka, Osijek, Zadar",
    utc: "+01:00",
  },
  {
    label: "Europe/Zurich (GMT+01:00)",
    value: "Europe/Zurich",
    name: "(GMT+01:00) Zürich, Genève, Basel, Lausanne, Bern",
    utc: "+01:00",
  },
  {
    label: "Africa/Blantyre (GMT+02:00)",
    value: "Africa/Blantyre",
    name: "(GMT+02:00) Lilongwe, Blantyre, Mzuzu, Zomba, Kasungu",
    utc: "+02:00",
  },
  {
    label: "Africa/Bujumbura (GMT+02:00)",
    value: "Africa/Bujumbura",
    name: "(GMT+02:00) Bujumbura, Muyinga, Gitega, Ruyigi, Ngozi",
    utc: "+02:00",
  },
  {
    label: "Africa/Cairo (GMT+02:00)",
    value: "Africa/Cairo",
    name: "(GMT+02:00) Cairo, Alexandria, Giza, Port Said, Suez",
    utc: "+02:00",
  },
  {
    label: "Africa/Gaborone (GMT+02:00)",
    value: "Africa/Gaborone",
    name: "(GMT+02:00) Gaborone, Francistown, Molepolole, Selebi-Phikwe, Maun",
    utc: "+02:00",
  },
  {
    label: "Africa/Harare (GMT+02:00)",
    value: "Africa/Harare",
    name: "(GMT+02:00) Harare, Bulawayo, Chitungwiza, Mutare, Gweru",
    utc: "+02:00",
  },
  {
    label: "Africa/Johannesburg (GMT+02:00)",
    value: "Africa/Johannesburg",
    name: "(GMT+02:00) Cape Town, Durban, Johannesburg, Soweto, Pretoria",
    utc: "+02:00",
  },
  {
    label: "Africa/Juba (GMT+02:00)",
    value: "Africa/Juba",
    name: "(GMT+02:00) Juba, Winejok, Malakal, Wau, Kuacjok",
    utc: "+02:00",
  },
  {
    label: "Africa/Khartoum (GMT+02:00)",
    value: "Africa/Khartoum",
    name: "(GMT+02:00) Khartoum, Omdurman, Nyala, Port Sudan, Kassala",
    utc: "+02:00",
  },
  {
    label: "Africa/Kigali (GMT+02:00)",
    value: "Africa/Kigali",
    name: "(GMT+02:00) Kigali, Butare, Gitarama, Musanze, Gisenyi",
    utc: "+02:00",
  },
  {
    label: "Africa/Lubumbashi (GMT+02:00)",
    value: "Africa/Lubumbashi",
    name: "(GMT+02:00) Lubumbashi, Mbuji-Mayi, Kisangani, Kananga, Likasi",
    utc: "+02:00",
  },
  {
    label: "Africa/Lusaka (GMT+02:00)",
    value: "Africa/Lusaka",
    name: "(GMT+02:00) Lusaka, Kitwe, Ndola, Kabwe, Chingola",
    utc: "+02:00",
  },
  {
    label: "Africa/Maputo (GMT+02:00)",
    value: "Africa/Maputo",
    name: "(GMT+02:00) Maputo, Matola, Beira, Nampula, Chimoio",
    utc: "+02:00",
  },
  {
    label: "Africa/Maseru (GMT+02:00)",
    value: "Africa/Maseru",
    name: "(GMT+02:00) Maseru, Mafeteng, Leribe, Maputsoe, Mohale’s Hoek",
    utc: "+02:00",
  },
  {
    label: "Africa/Mbabane (GMT+02:00)",
    value: "Africa/Mbabane",
    name: "(GMT+02:00) Manzini, Mbabane, Big Bend, Malkerns, Nhlangano",
    utc: "+02:00",
  },
  {
    label: "Africa/Tripoli (GMT+02:00)",
    value: "Africa/Tripoli",
    name: "(GMT+02:00) Tripoli, Benghazi, Mişrātah, Tarhuna, Al Khums",
    utc: "+02:00",
  },
  {
    label: "Asia/Amman (GMT+02:00)",
    value: "Asia/Amman",
    name: "(GMT+02:00) Amman, Zarqa, Irbid, Russeifa, Wādī as Sīr",
    utc: "+02:00",
  },
  {
    label: "Asia/Beirut (GMT+02:00)",
    value: "Asia/Beirut",
    name: "(GMT+02:00) Beirut, Ra’s Bayrūt, Tripoli, Sidon, Tyre",
    utc: "+02:00",
  },
  {
    label: "Asia/Damascus (GMT+02:00)",
    value: "Asia/Damascus",
    name: "(GMT+02:00) Aleppo, Damascus, Homs, Ḩamāh, Latakia",
    utc: "+02:00",
  },
  {
    label: "Asia/Famagusta (GMT+02:00)",
    value: "Asia/Famagusta",
    name: "(GMT+02:00) Famagusta, Kyrenia, Protaras, Paralímni, Lápithos",
    utc: "+02:00",
  },
  {
    label: "Asia/Gaza (GMT+02:00)",
    value: "Asia/Gaza",
    name: "(GMT+02:00) Gaza, Khān Yūnis, Jabālyā, Rafaḩ, Dayr al Balaḩ",
    utc: "+02:00",
  },
  {
    label: "Asia/Hebron (GMT+02:00)",
    value: "Asia/Hebron",
    name: "(GMT+02:00) East Jerusalem, Hebron, Nablus, Battir, Ţūlkarm",
    utc: "+02:00",
  },
  {
    label: "Asia/Jerusalem (GMT+02:00)",
    value: "Asia/Jerusalem",
    name: "(GMT+02:00) Jerusalem, Tel Aviv, West Jerusalem, Haifa, Ashdod",
    utc: "+02:00",
  },
  {
    label: "Asia/Nicosia (GMT+02:00)",
    value: "Asia/Nicosia",
    name: "(GMT+02:00) Nicosia, Limassol, Larnaca, Stróvolos, Paphos",
    utc: "+02:00",
  },
  {
    label: "Europe/Athens (GMT+02:00)",
    value: "Europe/Athens",
    name: "(GMT+02:00) Athens, Thessaloníki, Pátra, Piraeus, Lárisa",
    utc: "+02:00",
  },
  {
    label: "Europe/Bucharest (GMT+02:00)",
    value: "Europe/Bucharest",
    name: "(GMT+02:00) Bucharest, Sector 3, Sector 6, Sector 2, Iaşi",
    utc: "+02:00",
  },
  {
    label: "Europe/Chisinau (GMT+02:00)",
    value: "Europe/Chisinau",
    name: "(GMT+02:00) Chisinau, Tiraspol, Bălţi, Bender, Rîbniţa",
    utc: "+02:00",
  },
  {
    label: "Europe/Helsinki (GMT+02:00)",
    value: "Europe/Helsinki",
    name: "(GMT+02:00) Helsinki, Espoo, Tampere, Vantaa, Turku",
    utc: "+02:00",
  },
  {
    label: "Europe/Kaliningrad (GMT+02:00)",
    value: "Europe/Kaliningrad",
    name: "(GMT+02:00) Kaliningrad, Chernyakhovsk, Sovetsk, Baltiysk, Gusev",
    utc: "+02:00",
  },
  {
    label: "Europe/Kiev (GMT+02:00)",
    value: "Europe/Kiev",
    name: "(GMT+02:00) Kyiv, Kharkiv, Donetsk, Odessa, Dnipro",
    utc: "+02:00",
  },
  {
    label: "Europe/Mariehamn (GMT+02:00)",
    value: "Europe/Mariehamn",
    name: "(GMT+02:00) Mariehamn",
    utc: "+02:00",
  },
  {
    label: "Europe/Riga (GMT+02:00)",
    value: "Europe/Riga",
    name: "(GMT+02:00) Riga, Daugavpils, Liepāja, Jelgava, Jūrmala",
    utc: "+02:00",
  },
  {
    label: "Europe/Sofia (GMT+02:00)",
    value: "Europe/Sofia",
    name: "(GMT+02:00) Sofia, Plovdiv, Varna, Burgas, Ruse",
    utc: "+02:00",
  },
  {
    label: "Europe/Tallinn (GMT+02:00)",
    value: "Europe/Tallinn",
    name: "(GMT+02:00) Tallinn, Tartu, Narva, Kohtla-Järve, Pärnu",
    utc: "+02:00",
  },
  {
    label: "Europe/Uzhgorod (GMT+02:00)",
    value: "Europe/Uzhgorod",
    name: "(GMT+02:00) Uzhgorod, Mukachevo, Khust, Berehove, Tyachiv",
    utc: "+02:00",
  },
  {
    label: "Europe/Vilnius (GMT+02:00)",
    value: "Europe/Vilnius",
    name: "(GMT+02:00) Vilnius, Kaunas, Klaipėda, Šiauliai, Panevėžys",
    utc: "+02:00",
  },
  {
    label: "Europe/Zaporozhye (GMT+02:00)",
    value: "Europe/Zaporozhye",
    name: "(GMT+02:00) Luhansk, Sevastopol, Sievierodonetsk, Alchevsk, Lysychansk",
    utc: "+02:00",
  },
  {
    label: "Africa/Addis_Ababa (GMT+03:00)",
    value: "Africa/Addis_Ababa",
    name: "(GMT+03:00) Addis Ababa, Dire Dawa, Mek'ele, Nazrēt, Bahir Dar",
    utc: "+03:00",
  },
  {
    label: "Africa/Asmara (GMT+03:00)",
    value: "Africa/Asmara",
    name: "(GMT+03:00) Asmara, Keren, Massawa, Assab, Mendefera",
    utc: "+03:00",
  },
  {
    label: "Africa/Dar_es_Salaam (GMT+03:00)",
    value: "Africa/Dar_es_Salaam",
    name: "(GMT+03:00) Dar es Salaam, Mwanza, Zanzibar, Arusha, Mbeya",
    utc: "+03:00",
  },
  {
    label: "Africa/Djibouti (GMT+03:00)",
    value: "Africa/Djibouti",
    name: "(GMT+03:00) Djibouti, 'Ali Sabieh, Tadjourah, Obock, Dikhil",
    utc: "+03:00",
  },
  {
    label: "Africa/Kampala (GMT+03:00)",
    value: "Africa/Kampala",
    name: "(GMT+03:00) Kampala, Gulu, Lira, Mbarara, Jinja",
    utc: "+03:00",
  },
  {
    label: "Africa/Mogadishu (GMT+03:00)",
    value: "Africa/Mogadishu",
    name: "(GMT+03:00) Mogadishu, Hargeysa, Berbera, Kismayo, Marka",
    utc: "+03:00",
  },
  {
    label: "Africa/Nairobi (GMT+03:00)",
    value: "Africa/Nairobi",
    name: "(GMT+03:00) Nairobi, Mombasa, Nakuru, Eldoret, Kisumu",
    utc: "+03:00",
  },
  {
    label: "Antarctica/Syowa (GMT+03:00)",
    value: "Antarctica/Syowa",
    name: "(GMT+03:00) Syowa",
    utc: "+03:00",
  },
  {
    label: "Asia/Aden (GMT+03:00)",
    value: "Asia/Aden",
    name: "(GMT+03:00) Sanaa, Al Ḩudaydah, Taiz, Aden, Mukalla",
    utc: "+03:00",
  },
  {
    label: "Asia/Baghdad (GMT+03:00)",
    value: "Asia/Baghdad",
    name: "(GMT+03:00) Baghdad, Basrah, Al Mawşil al Jadīdah, Al Başrah al Qadīmah, Mosul",
    utc: "+03:00",
  },
  {
    label: "Asia/Bahrain (GMT+03:00)",
    value: "Asia/Bahrain",
    name: "(GMT+03:00) Manama, Al Muharraq, Ar Rifā‘, Dār Kulayb, Madīnat Ḩamad",
    utc: "+03:00",
  },
  {
    label: "Asia/Kuwait (GMT+03:00)",
    value: "Asia/Kuwait",
    name: "(GMT+03:00) Al Aḩmadī, Ḩawallī, As Sālimīyah, Şabāḩ as Sālim, Al Farwānīyah",
    utc: "+03:00",
  },
  {
    label: "Asia/Qatar (GMT+03:00)",
    value: "Asia/Qatar",
    name: "(GMT+03:00) Doha, Ar Rayyān, Umm Şalāl Muḩammad, Al Wakrah, Al Khawr",
    utc: "+03:00",
  },
  {
    label: "Asia/Riyadh (GMT+03:00)",
    value: "Asia/Riyadh",
    name: "(GMT+03:00) Riyadh, Jeddah, Mecca, Medina, Sulţānah",
    utc: "+03:00",
  },
  {
    label: "Europe/Istanbul (GMT+03:00)",
    value: "Europe/Istanbul",
    name: "(GMT+03:00) Istanbul, Ankara, İzmir, Bursa, Adana",
    utc: "+03:00",
  },
  {
    label: "Europe/Kirov (GMT+03:00)",
    value: "Europe/Kirov",
    name: "(GMT+03:00) Kirov, Kirovo-Chepetsk, Vyatskiye Polyany, Slobodskoy, Kotel’nich",
    utc: "+03:00",
  },
  {
    label: "Europe/Minsk (GMT+03:00)",
    value: "Europe/Minsk",
    name: "(GMT+03:00) Minsk, Homyel', Mahilyow, Vitebsk, Hrodna",
    utc: "+03:00",
  },
  {
    label: "Europe/Moscow (GMT+03:00)",
    value: "Europe/Moscow",
    name: "(GMT+03:00) Moscow, Saint Petersburg, Nizhniy Novgorod, Kazan, Rostov-na-Donu",
    utc: "+03:00",
  },
  {
    label: "Europe/Simferopol (GMT+03:00)",
    value: "Europe/Simferopol",
    name: "(GMT+03:00) Simferopol, Kerch, Yevpatoriya, Yalta, Feodosiya",
    utc: "+03:00",
  },
  {
    label: "Europe/Volgograd (GMT+03:00)",
    value: "Europe/Volgograd",
    name: "(GMT+03:00) Volgograd, Volzhskiy, Kamyshin, Mikhaylovka, Uryupinsk",
    utc: "+03:00",
  },
  {
    label: "Indian/Antananarivo (GMT+03:00)",
    value: "Indian/Antananarivo",
    name: "(GMT+03:00) Antananarivo, Toamasina, Antsirabe, Fianarantsoa, Mahajanga",
    utc: "+03:00",
  },
  {
    label: "Indian/Comoro (GMT+03:00)",
    value: "Indian/Comoro",
    name: "(GMT+03:00) Moroni, Moutsamoudou, Fomboni, Domoni, Tsimbeo",
    utc: "+03:00",
  },
  {
    label: "Indian/Mayotte (GMT+03:00)",
    value: "Indian/Mayotte",
    name: "(GMT+03:00) Mamoudzou, Koungou, Dzaoudzi, Dembeni, Sada",
    utc: "+03:00",
  },
  {
    label: "Asia/Tehran (GMT+03:30)",
    value: "Asia/Tehran",
    name: "(GMT+03:30) Tehran, Mashhad, Isfahan, Karaj, Tabriz",
    utc: "+03:30",
  },
  {
    label: "Asia/Baku (GMT+04:00)",
    value: "Asia/Baku",
    name: "(GMT+04:00) Baku, Ganja, Sumqayıt, Lankaran, Yevlakh",
    utc: "+04:00",
  },
  {
    label: "Asia/Dubai (GMT+04:00)",
    value: "Asia/Dubai",
    name: "(GMT+04:00) Dubai, Sharjah, Abu Dhabi, Ajman City, Ras Al Khaimah City",
    utc: "+04:00",
  },
  {
    label: "Asia/Muscat (GMT+04:00)",
    value: "Asia/Muscat",
    name: "(GMT+04:00) Muscat, Seeb, Şalālah, Bawshar, Sohar",
    utc: "+04:00",
  },
  {
    label: "Asia/Tbilisi (GMT+04:00)",
    value: "Asia/Tbilisi",
    name: "(GMT+04:00) Tbilisi, Kutaisi, Batumi, Sokhumi, Zugdidi",
    utc: "+04:00",
  },
  {
    label: "Asia/Yerevan (GMT+04:00)",
    value: "Asia/Yerevan",
    name: "(GMT+04:00) Yerevan, Gyumri, Vanadzor, Vagharshapat, Hrazdan",
    utc: "+04:00",
  },
  {
    label: "Europe/Astrakhan (GMT+04:00)",
    value: "Europe/Astrakhan",
    name: "(GMT+04:00) Astrakhan, Akhtubinsk, Znamensk, Kharabali, Kamyzyak",
    utc: "+04:00",
  },
  {
    label: "Europe/Samara (GMT+04:00)",
    value: "Europe/Samara",
    name: "(GMT+04:00) Samara, Togliatti-on-the-Volga, Izhevsk, Syzran’, Novokuybyshevsk",
    utc: "+04:00",
  },
  {
    label: "Europe/Saratov (GMT+04:00)",
    value: "Europe/Saratov",
    name: "(GMT+04:00) Saratov, Balakovo, Engel’s, Balashov, Vol’sk",
    utc: "+04:00",
  },
  {
    label: "Europe/Ulyanovsk (GMT+04:00)",
    value: "Europe/Ulyanovsk",
    name: "(GMT+04:00) Ulyanovsk, Dimitrovgrad, Inza, Barysh, Novoul’yanovsk",
    utc: "+04:00",
  },
  {
    label: "Indian/Mahe (GMT+04:00)",
    value: "Indian/Mahe",
    name: "(GMT+04:00) Victoria, Anse Boileau, Bel Ombre, Beau Vallon, Cascade",
    utc: "+04:00",
  },
  {
    label: "Indian/Mauritius (GMT+04:00)",
    value: "Indian/Mauritius",
    name: "(GMT+04:00) Port Louis, Beau Bassin-Rose Hill, Vacoas, Curepipe, Quatre Bornes",
    utc: "+04:00",
  },
  {
    label: "Indian/Reunion (GMT+04:00)",
    value: "Indian/Reunion",
    name: "(GMT+04:00) Saint-Denis, Saint-Paul, Saint-Pierre, Le Tampon, Saint-André",
    utc: "+04:00",
  },
  {
    label: "Asia/Kabul (GMT+04:30)",
    value: "Asia/Kabul",
    name: "(GMT+04:30) Kabul, Kandahār, Mazār-e Sharīf, Herāt, Jalālābād",
    utc: "+04:30",
  },
  {
    label: "Antarctica/Mawson (GMT+05:00)",
    value: "Antarctica/Mawson",
    name: "(GMT+05:00) Mawson",
    utc: "+05:00",
  },
  {
    label: "Asia/Aqtau (GMT+05:00)",
    value: "Asia/Aqtau",
    name: "(GMT+05:00) Shevchenko, Zhanaozen, Beyneu, Shetpe, Yeraliyev",
    utc: "+05:00",
  },
  {
    label: "Asia/Aqtobe (GMT+05:00)",
    value: "Asia/Aqtobe",
    name: "(GMT+05:00) Aktobe, Kandyagash, Shalqar, Khromtau, Embi",
    utc: "+05:00",
  },
  {
    label: "Asia/Ashgabat (GMT+05:00)",
    value: "Asia/Ashgabat",
    name: "(GMT+05:00) Ashgabat, Türkmenabat, Daşoguz, Mary, Balkanabat",
    utc: "+05:00",
  },
  {
    label: "Asia/Atyrau (GMT+05:00)",
    value: "Asia/Atyrau",
    name: "(GMT+05:00) Atyrau, Qulsary, Shalkar, Balykshi, Maqat",
    utc: "+05:00",
  },
  {
    label: "Asia/Dushanbe (GMT+05:00)",
    value: "Asia/Dushanbe",
    name: "(GMT+05:00) Dushanbe, Khujand, Kŭlob, Bokhtar, Istaravshan",
    utc: "+05:00",
  },
  {
    label: "Asia/Karachi (GMT+05:00)",
    value: "Asia/Karachi",
    name: "(GMT+05:00) Karachi, Lahore, Faisalabad, Rawalpindi, Multan",
    utc: "+05:00",
  },
  {
    label: "Asia/Oral (GMT+05:00)",
    value: "Asia/Oral",
    name: "(GMT+05:00) Oral, Aqsay, Zhänibek, Tasqala, Zhumysker",
    utc: "+05:00",
  },
  {
    label: "Asia/Qyzylorda (GMT+05:00)",
    value: "Asia/Qyzylorda",
    name: "(GMT+05:00) Kyzylorda, Baikonur, Novokazalinsk, Aral, Chiili",
    utc: "+05:00",
  },
  {
    label: "Asia/Samarkand (GMT+05:00)",
    value: "Asia/Samarkand",
    name: "(GMT+05:00) Samarkand, Bukhara, Nukus, Qarshi, Jizzax",
    utc: "+05:00",
  },
  {
    label: "Asia/Tashkent (GMT+05:00)",
    value: "Asia/Tashkent",
    name: "(GMT+05:00) Tashkent, Namangan, Andijon, Qo‘qon, Chirchiq",
    utc: "+05:00",
  },
  {
    label: "Asia/Yekaterinburg (GMT+05:00)",
    value: "Asia/Yekaterinburg",
    name: "(GMT+05:00) Yekaterinburg, Chelyabinsk, Ufa, Perm, Orenburg",
    utc: "+05:00",
  },
  {
    label: "Indian/Kerguelen (GMT+05:00)",
    value: "Indian/Kerguelen",
    name: "(GMT+05:00) Port-aux-Français",
    utc: "+05:00",
  },
  {
    label: "Indian/Maldives (GMT+05:00)",
    value: "Indian/Maldives",
    name: "(GMT+05:00) Male, Fuvahmulah, Hithadhoo, Kulhudhuffushi, Thinadhoo",
    utc: "+05:00",
  },
  {
    label: "Asia/Colombo (GMT+05:30)",
    value: "Asia/Colombo",
    name: "(GMT+05:30) Colombo, Dehiwala-Mount Lavinia, Moratuwa, Jaffna, Negombo",
    utc: "+05:30",
  },
  {
    label: "Asia/Kolkata (GMT+05:30)",
    value: "Asia/Kolkata",
    name: "(GMT+05:30) Mumbai, Delhi, Bengaluru, Kolkata, Chennai",
    utc: "+05:30",
  },
  {
    label: "Asia/Kathmandu (GMT+05:45)",
    value: "Asia/Kathmandu",
    name: "(GMT+05:45) Kathmandu, Pokhara, Pātan, Biratnagar, Birgañj",
    utc: "+05:45",
  },
  {
    label: "Antarctica/Vostok (GMT+06:00)",
    value: "Antarctica/Vostok",
    name: "(GMT+06:00) Vostok",
    utc: "+06:00",
  },
  {
    label: "Asia/Almaty (GMT+06:00)",
    value: "Asia/Almaty",
    name: "(GMT+06:00) Almaty, Karagandy, Shymkent, Taraz, Nur-Sultan",
    utc: "+06:00",
  },
  {
    label: "Asia/Bishkek (GMT+06:00)",
    value: "Asia/Bishkek",
    name: "(GMT+06:00) Bishkek, Osh, Jalal-Abad, Karakol, Tokmok",
    utc: "+06:00",
  },
  {
    label: "Asia/Dhaka (GMT+06:00)",
    value: "Asia/Dhaka",
    name: "(GMT+06:00) Dhaka, Chattogram, Khulna, Rājshāhi, Comilla",
    utc: "+06:00",
  },
  {
    label: "Asia/Omsk (GMT+06:00)",
    value: "Asia/Omsk",
    name: "(GMT+06:00) Omsk, Tara, Kalachinsk, Znamenskoye, Tavricheskoye",
    utc: "+06:00",
  },
  {
    label: "Asia/Qostanay (GMT+06:00)",
    value: "Asia/Qostanay",
    name: "(GMT+06:00) Kostanay, Rudnyy, Dzhetygara, Arkalyk, Lisakovsk",
    utc: "+06:00",
  },
  {
    label: "Asia/Thimphu (GMT+06:00)",
    value: "Asia/Thimphu",
    name: "(GMT+06:00) himphu, Punākha, Tsirang, Phuntsholing, Pemagatshel",
    utc: "+06:00",
  },
  {
    label: "Asia/Urumqi (GMT+06:00)",
    value: "Asia/Urumqi",
    name: "(GMT+06:00) Zhongshan, Ürümqi, Zhanjiang, Shihezi, Huocheng",
    utc: "+06:00",
  },
  {
    label: "Indian/Chagos (GMT+06:00)",
    value: "Indian/Chagos",
    name: "(GMT+06:00) British Indian Ocean Territory",
    utc: "+06:00",
  },
  {
    label: "Asia/Yangon (GMT+06:30)",
    value: "Asia/Yangon",
    name: "(GMT+06:30) Yangon, Mandalay, Nay Pyi Taw, Mawlamyine, Kyain Seikgyi Township",
    utc: "+06:30",
  },
  {
    label: "Indian/Cocos (GMT+06:30)",
    value: "Indian/Cocos",
    name: "(GMT+06:30) West Island",
    utc: "+06:30",
  },
  {
    label: "Antarctica/Davis (GMT+07:00)",
    value: "Antarctica/Davis",
    name: "(GMT+07:00) Davis",
    utc: "+07:00",
  },
  {
    label: "Asia/Bangkok (GMT+07:00)",
    value: "Asia/Bangkok",
    name: "(GMT+07:00) Bangkok, Hanoi, Haiphong, Samut Prakan, Mueang Nonthaburi",
    utc: "+07:00",
  },
  {
    label: "Asia/Barnaul (GMT+07:00)",
    value: "Asia/Barnaul",
    name: "(GMT+07:00) Barnaul, Biysk, Rubtsovsk, Novoaltaysk, Gorno-Altaysk",
    utc: "+07:00",
  },
  {
    label: "Asia/Hovd (GMT+07:00)",
    value: "Asia/Hovd",
    name: "(GMT+07:00) Khovd, Ölgii, Ulaangom, Uliastay, Altai",
    utc: "+07:00",
  },
  {
    label: "Asia/Ho_Chi_Minh (GMT+07:00)",
    value: "Asia/Ho_Chi_Minh",
    name: "(GMT+07:00) Ho Chi Minh City, Da Nang, Biên Hòa, Nha Trang, Cần Thơ",
    utc: "+07:00",
  },
  {
    label: "Asia/Jakarta (GMT+07:00)",
    value: "Asia/Jakarta",
    name: "(GMT+07:00) Jakarta, Surabaya, Medan, Bandung, Bekasi",
    utc: "+07:00",
  },
  {
    label: "Asia/Krasnoyarsk (GMT+07:00)",
    value: "Asia/Krasnoyarsk",
    name: "(GMT+07:00) Krasnoyarsk, Abakan, Norilsk, Achinsk, Kyzyl",
    utc: "+07:00",
  },
  {
    label: "Asia/Novokuznetsk (GMT+07:00)",
    value: "Asia/Novokuznetsk",
    name: "(GMT+07:00) Novokuznetsk, Kemerovo, Prokop’yevsk, Leninsk-Kuznetsky, Kiselëvsk",
    utc: "+07:00",
  },
  {
    label: "Asia/Novosibirsk (GMT+07:00)",
    value: "Asia/Novosibirsk",
    name: "(GMT+07:00) Novosibirsk, Berdsk, Iskitim, Akademgorodok, Kuybyshev",
    utc: "+07:00",
  },
  {
    label: "Asia/Phnom_Penh (GMT+07:00)",
    value: "Asia/Phnom_Penh",
    name: "(GMT+07:00) Phnom Penh, Takeo, Sihanoukville, Battambang, Siem Reap",
    utc: "+07:00",
  },
  {
    label: "Asia/Pontianak (GMT+07:00)",
    value: "Asia/Pontianak",
    name: "(GMT+07:00) Pontianak, Tanjung Pinang, Palangkaraya, Singkawang, Sampit",
    utc: "+07:00",
  },
  {
    label: "Asia/Tomsk (GMT+07:00)",
    value: "Asia/Tomsk",
    name: "(GMT+07:00) Tomsk, Seversk, Strezhevoy, Kolpashevo, Asino",
    utc: "+07:00",
  },
  {
    label: "Asia/Vientiane (GMT+07:00)",
    value: "Asia/Vientiane",
    name: "(GMT+07:00) Vientiane, Pakse, Thakhèk, Savannakhet, Luang Prabang",
    utc: "+07:00",
  },
  {
    label: "Indian/Christmas (GMT+07:00)",
    value: "Indian/Christmas",
    name: "(GMT+07:00) Flying Fish Cove",
    utc: "+07:00",
  },
  {
    label: "Asia/Brunei (GMT+08:00)",
    value: "Asia/Brunei",
    name: "(GMT+08:00) Bandar Seri Begawan, Kuala Belait, Seria, Tutong, Bangar",
    utc: "+08:00",
  },
  {
    label: "Asia/Choibalsan (GMT+08:00)",
    value: "Asia/Choibalsan",
    name: "(GMT+08:00) Baruun-Urt, Choibalsan",
    utc: "+08:00",
  },
  {
    label: "Asia/Hong_Kong (GMT+08:00)",
    value: "Asia/Hong_Kong",
    name: "(GMT+08:00) Hong Kong, Kowloon, Tsuen Wan, Yuen Long Kau Hui, Tung Chung",
    utc: "+08:00",
  },
  {
    label: "Asia/Irkutsk (GMT+08:00)",
    value: "Asia/Irkutsk",
    name: "(GMT+08:00) Irkutsk, Ulan-Ude, Bratsk, Angarsk, Ust’-Ilimsk",
    utc: "+08:00",
  },
  {
    label: "Asia/Kuala_Lumpur (GMT+08:00)",
    value: "Asia/Kuala_Lumpur",
    name: "(GMT+08:00) Kota Bharu, Kuala Lumpur, Klang, Kampung Baru Subang, Johor Bahru",
    utc: "+08:00",
  },
  {
    label: "Asia/Kuching (GMT+08:00)",
    value: "Asia/Kuching",
    name: "(GMT+08:00) Kuching, Kota Kinabalu, Sandakan, Tawau, Miri",
    utc: "+08:00",
  },
  {
    label: "Asia/Macau (GMT+08:00)",
    value: "Asia/Macau",
    name: "(GMT+08:00) Macau",
    utc: "+08:00",
  },
  {
    label: "Asia/Makassar (GMT+08:00)",
    value: "Asia/Makassar",
    name: "(GMT+08:00) Makassar, Denpasar, City of Balikpapan, Banjarmasin, Manado",
    utc: "+08:00",
  },
  {
    label: "Asia/Manila (GMT+08:00)",
    value: "Asia/Manila",
    name: "(GMT+08:00) Quezon City, Manila, Caloocan City, Budta, Davao",
    utc: "+08:00",
  },
  {
    label: "Asia/Shanghai (GMT+08:00)",
    value: "Asia/Shanghai",
    name: "(GMT+08:00) Shanghai, Beijing, Tianjin, Guangzhou, Shenzhen",
    utc: "+08:00",
  },
  {
    label: "Asia/Singapore (GMT+08:00)",
    value: "Asia/Singapore",
    name: "(GMT+08:00) Singapore, Woodlands",
    utc: "+08:00",
  },
  {
    label: "Asia/Taipei (GMT+08:00)",
    value: "Asia/Taipei",
    name: "(GMT+08:00) Taipei, Kaohsiung, Taichung, Tainan, Banqiao",
    utc: "+08:00",
  },
  {
    label: "Asia/Ulaanbaatar (GMT+08:00)",
    value: "Asia/Ulaanbaatar",
    name: "(GMT+08:00) Ulan Bator, Erdenet, Darhan, Hovd, Mörön",
    utc: "+08:00",
  },
  {
    label: "Australia/Perth (GMT+08:00)",
    value: "Australia/Perth",
    name: "(GMT+08:00) Perth, Rockingham, Mandurah, Bunbury, Albany",
    utc: "+08:00",
  },
  {
    label: "Australia/Eucla (GMT+08:45)",
    value: "Australia/Eucla",
    name: "(GMT+08:45) Eucla",
    utc: "+08:45",
  },
  {
    label: "Asia/Chita (GMT+09:00)",
    value: "Asia/Chita",
    name: "(GMT+09:00) Chita, Krasnokamensk, Borzya, Petrovsk-Zabaykal’skiy, Aginskoye",
    utc: "+09:00",
  },
  {
    label: "Asia/Dili (GMT+09:00)",
    value: "Asia/Dili",
    name: "(GMT+09:00) Dili, Maliana, Suai, Likisá, Aileu",
    utc: "+09:00",
  },
  {
    label: "Asia/Jayapura (GMT+09:00)",
    value: "Asia/Jayapura",
    name: "(GMT+09:00) Ambon, Jayapura, Sorong, Ternate, Abepura",
    utc: "+09:00",
  },
  {
    label: "Asia/Khandyga (GMT+09:00)",
    value: "Asia/Khandyga",
    name: "(GMT+09:00) Khandyga",
    utc: "+09:00",
  },
  {
    label: "Asia/Pyongyang (GMT+09:00)",
    value: "Asia/Pyongyang",
    name: "(GMT+09:00) Pyongyang, Hamhŭng, Namp’o, Sunch’ŏn, Hŭngnam",
    utc: "+09:00",
  },
  {
    label: "Asia/Seoul (GMT+09:00)",
    value: "Asia/Seoul",
    name: "(GMT+09:00) Seoul, Busan, Incheon, Daegu, Daejeon",
    utc: "+09:00",
  },
  {
    label: "Asia/Tokyo (GMT+09:00)",
    value: "Asia/Tokyo",
    name: "(GMT+09:00) Tokyo, Yokohama, Osaka, Nagoya, Sapporo",
    utc: "+09:00",
  },
  {
    label: "Asia/Yakutsk (GMT+09:00)",
    value: "Asia/Yakutsk",
    name: "(GMT+09:00) Yakutsk, Blagoveshchensk, Belogorsk, Neryungri, Svobodnyy",
    utc: "+09:00",
  },
  {
    label: "Pacific/Palau (GMT+09:00)",
    value: "Pacific/Palau",
    name: "(GMT+09:00) Koror, Koror Town, Kloulklubed, Ulimang, Mengellang",
    utc: "+09:00",
  },
  {
    label: "Australia/Adelaide (GMT+09:30)",
    value: "Australia/Adelaide",
    name: "(GMT+09:30) Adelaide, Adelaide Hills, Mount Gambier, Morphett Vale, Gawler",
    utc: "+09:30",
  },
  {
    label: "Australia/Broken_Hill (GMT+09:30)",
    value: "Australia/Broken_Hill",
    name: "(GMT+09:30) Broken Hill",
    utc: "+09:30",
  },
  {
    label: "Australia/Darwin (GMT+09:30)",
    value: "Australia/Darwin",
    name: "(GMT+09:30) Darwin, Alice Springs, Palmerston, Howard Springs",
    utc: "+09:30",
  },
  {
    label: "Antarctica/DumontDUrville (GMT+10:00)",
    value: "Antarctica/DumontDUrville",
    name: "(GMT+10:00) DumontDUrville",
    utc: "+10:00",
  },
  {
    label: "Antarctica/Macquarie (GMT+10:00)",
    value: "Antarctica/Macquarie",
    name: "(GMT+10:00) Macquarie",
    utc: "+10:00",
  },
  {
    label: "Asia/Ust-Nera (GMT+10:00)",
    value: "Asia/Ust-Nera",
    name: "(GMT+10:00) Ust-Nera",
    utc: "+10:00",
  },
  {
    label: "Asia/Vladivostok (GMT+10:00)",
    value: "Asia/Vladivostok",
    name: "(GMT+10:00) Vladivostok, Khabarovsk, Khabarovsk Vtoroy, Komsomolsk-on-Amur, Ussuriysk",
    utc: "+10:00",
  },
  {
    label: "Australia/Brisbane (GMT+10:00)",
    value: "Australia/Brisbane",
    name: "(GMT+10:00) Brisbane, Gold Coast, Logan City, Townsville, Cairns",
    utc: "+10:00",
  },
  {
    label: "Australia/Currie (GMT+10:00)",
    value: "Australia/Currie",
    name: "(GMT+10:00) Currie",
    utc: "+10:00",
  },
  {
    label: "Australia/Hobart (GMT+10:00)",
    value: "Australia/Hobart",
    name: "(GMT+10:00) Hobart, Launceston, Burnie, Devonport, Sandy Bay",
    utc: "+10:00",
  },
  {
    label: "Australia/Lindeman (GMT+10:00)",
    value: "Australia/Lindeman",
    name: "(GMT+10:00) Lindeman",
    utc: "+10:00",
  },
  {
    label: "Australia/Melbourne (GMT+10:00)",
    value: "Australia/Melbourne",
    name: "(GMT+10:00) Melbourne, Geelong, Bendigo, Ballarat, Melbourne City Centre",
    utc: "+10:00",
  },
  {
    label: "Australia/Sydney (GMT+10:00)",
    value: "Australia/Sydney",
    name: "(GMT+10:00) Sydney, Canberra, Newcastle, Wollongong, Maitland",
    utc: "+10:00",
  },
  {
    label: "Pacific/Chuuk (GMT+10:00)",
    value: "Pacific/Chuuk",
    name: "(GMT+10:00) Weno, Colonia",
    utc: "+10:00",
  },
  {
    label: "Pacific/GuamVillage (GMT+10:00)",
    value: "Pacific/GuamVillage",
    name: "(GMT+10:00) Dededo Village, Yigo Village, Tamuning, Tamuning-Tumon-Harmon Village, Mangilao Village",
    utc: "+10:00",
  },
  {
    label: "Pacific/Port_Moresby (GMT+10:00)",
    value: "Pacific/Port_Moresby",
    name: "(GMT+10:00) Port Moresby, Lae, Mount Hagen, Popondetta, Madang",
    utc: "+10:00",
  },
  {
    label: "Pacific/Saipan (GMT+10:00)",
    value: "Pacific/Saipan",
    name: "(GMT+10:00) Saipan, San Jose Village",
    utc: "+10:00",
  },
  {
    label: "Australia/Lord_Howe (GMT+10:30)",
    value: "Australia/Lord_Howe",
    name: "(GMT+10:30) Lord Howe",
    utc: "+10:30",
  },
  {
    label: "Antarctica/Casey (GMT+11:00)",
    value: "Antarctica/Casey",
    name: "(GMT+11:00) Casey",
    utc: "+11:00",
  },
  {
    label: "Asia/Magadan (GMT+11:00)",
    value: "Asia/Magadan",
    name: "(GMT+11:00) Magadan, Ust-Nera, Susuman, Ola",
    utc: "+11:00",
  },
  {
    label: "Asia/Sakhalin (GMT+11:00)",
    value: "Asia/Sakhalin",
    name: "(GMT+11:00) Yuzhno-Sakhalinsk, Korsakov, Kholmsk, Okha, Nevel’sk",
    utc: "+11:00",
  },
  {
    label: "Asia/Srednekolymsk (GMT+11:00)",
    value: "Asia/Srednekolymsk",
    name: "(GMT+11:00) Srednekolymsk",
    utc: "+11:00",
  },
  {
    label: "Pacific/Bougainville (GMT+11:00)",
    value: "Pacific/Bougainville",
    name: "(GMT+11:00) Arawa, Buka",
    utc: "+11:00",
  },
  {
    label: "Pacific/Efate (GMT+11:00)",
    value: "Pacific/Efate",
    name: "(GMT+11:00) Port-Vila, Luganville, Isangel, Sola, Lakatoro",
    utc: "+11:00",
  },
  {
    label: "Pacific/Guadalcanal (GMT+11:00)",
    value: "Pacific/Guadalcanal",
    name: "(GMT+11:00) Honiara, Malango, Auki, Gizo, Buala",
    utc: "+11:00",
  },
  {
    label: "Pacific/Kosrae (GMT+11:00)",
    value: "Pacific/Kosrae",
    name: "(GMT+11:00) Tofol",
    utc: "+11:00",
  },
  {
    label: "Pacific/Norfolk (GMT+11:00)",
    value: "Pacific/Norfolk",
    name: "(GMT+11:00) Kingston",
    utc: "+11:00",
  },
  {
    label: "Pacific/Noumea (GMT+11:00)",
    value: "Pacific/Noumea",
    name: "(GMT+11:00) Nouméa, Mont-Dore, Dumbéa, Païta, Wé",
    utc: "+11:00",
  },
  {
    label: "Pacific/Pohnpei (GMT+11:00)",
    value: "Pacific/Pohnpei",
    name: "(GMT+11:00) Kolonia, Kolonia Town, Palikir - National Government Center",
    utc: "+11:00",
  },
  {
    label: "Antarctica/McMurdo (GMT+12:00)",
    value: "Antarctica/McMurdo",
    name: "(GMT+12:00) McMurdo",
    utc: "+12:00",
  },
  {
    label: "Asia/Anadyr (GMT+12:00)",
    value: "Asia/Anadyr",
    name: "(GMT+12:00) Anadyr, Bilibino",
    utc: "+12:00",
  },
  {
    label: "Asia/Kamchatka (GMT+12:00)",
    value: "Asia/Kamchatka",
    name: "(GMT+12:00) Petropavlovsk-Kamchatsky, Yelizovo, Vilyuchinsk, Klyuchi, Mil’kovo",
    utc: "+12:00",
  },
  {
    label: "Pacific/Auckland (GMT+12:00)",
    value: "Pacific/Auckland",
    name: "(GMT+12:00) Auckland, Wellington, Christchurch, Manukau City, North Shore",
    utc: "+12:00",
  },
  {
    label: "Pacific/Fiji (GMT+12:00)",
    value: "Pacific/Fiji",
    name: "(GMT+12:00) Suva, Lautoka, Nadi, Labasa, Ba",
    utc: "+12:00",
  },
  {
    label: "Pacific/Funafuti (GMT+12:00)",
    value: "Pacific/Funafuti",
    name: "(GMT+12:00) Funafuti, Savave Village, Tanrake Village, Toga Village, Asau Village",
    utc: "+12:00",
  },
  {
    label: "Pacific/Kwajalein (GMT+12:00)",
    value: "Pacific/Kwajalein",
    name: "(GMT+12:00) Ebaye, Jabat",
    utc: "+12:00",
  },
  {
    label: "Pacific/Majuro (GMT+12:00)",
    value: "Pacific/Majuro",
    name: "(GMT+12:00) Majuro, Arno, Jabor, Wotje, Mili",
    utc: "+12:00",
  },
  {
    label: "Pacific/Nauru (GMT+12:00)",
    value: "Pacific/Nauru",
    name: "(GMT+12:00) Yaren, Baiti, Anabar, Uaboe, Ijuw",
    utc: "+12:00",
  },
  {
    label: "Pacific/Tarawa (GMT+12:00)",
    value: "Pacific/Tarawa",
    name: "(GMT+12:00) Tarawa, Betio Village, Bikenibeu Village",
    utc: "+12:00",
  },
  {
    label: "Pacific/Wake (GMT+12:00)",
    value: "Pacific/Wake",
    name: "(GMT+12:00) Wake",
    utc: "+12:00",
  },
  {
    label: "Pacific/Wallis (GMT+12:00)",
    value: "Pacific/Wallis",
    name: "(GMT+12:00) Mata-Utu, Leava, Alo",
    utc: "+12:00",
  },
  {
    label: "Pacific/Chatham (GMT+12:45)",
    value: "Pacific/Chatham",
    name: "(GMT+12:45) Waitangi",
    utc: "+12:45",
  },
  {
    label: "Pacific/Apia (GMT+13:00)",
    value: "Pacific/Apia",
    name: "(GMT+13:00) Apia, Asau, Mulifanua, Afega, Leulumoega",
    utc: "+13:00",
  },
  {
    label: "Pacific/Enderbury (GMT+13:00)",
    value: "Pacific/Enderbury",
    name: "(GMT+13:00) Enderbury",
    utc: "+13:00",
  },
  {
    label: "Pacific/Fakaofo (GMT+13:00)",
    value: "Pacific/Fakaofo",
    name: "(GMT+13:00) Atafu Village, Nukunonu, Fale old settlement",
    utc: "+13:00",
  },
  {
    label: "Pacific/Tongatapu (GMT+13:00)",
    value: "Pacific/Tongatapu",
    name: "(GMT+13:00) Nuku‘alofa, Lapaha, Neiafu, Pangai, ‘Ohonua",
    utc: "+13:00",
  },
  {
    label: "Pacific/Kiritimati (GMT+14:00)",
    value: "Pacific/Kiritimati",
    name: "(GMT+14:00) Kiritimati",
    utc: "+14:00",
  },
];

import awsimg from "../assets/images/aws.png";
import doimg from "../assets/images/digitalOcean.png";
import goimg from "../assets/images/google.png";
import waimg from "../assets/images/wasabi.png";
import azimg from "../assets/images/azure.png";
import linimg from "../assets/images/linode.png";
import backimg from "../assets/images/backblaze.png";
import cldimg from "../assets/images/cloudflare.png";

export const NEW_SOURCE_DATA = {
  wordpress: {
    image: "https://assets.gumlet.io/public/img/wordpress-logo-dashboard.png",
    maxHeight: "3rem",
    maxWidth: "",
    title: "Wordpress",
    description: "For WordPress and Woocommerce sites",
    url_key: "wordpress",
  },
  web: {
    image: "https://www.gumlet.com/public/dashboard/img/folder.png",
    maxHeight: "",
    maxWidth: "3rem",
    title: "Web Folder",
    description: "For all kinds of sites and apps. Web Proxy can be configured",
    url_key: "web",
    marginBottom: "8px",
  },
  aws: {
    image: awsimg,
    maxHeight: "",
    maxWidth: "18rem",
    title: "Amazon S3",
    description: "For images stored in S3 bucket",
    url_key: "aws",
    marginBottom: "15px",
  },
  dostorage: {
    image: doimg,
    maxHeight: "",
    maxWidth: "3.5rem",
    title: "DigitalOcean",
    description: "For images stored in DO Spaces",
    url_key: "dostorage",
  },
  gcs: {
    image: goimg,
    maxHeight: "",
    maxWidth: "10rem",
    title: "Google Cloud Storage",
    description: "For images stored in GCS",
    url_key: "gcs",
    marginBottom: "20px",
  },
  wasabi: {
    image: waimg,
    maxHeight: "",
    maxWidth: "10rem",
    title: "Wasabi Storage",
    description: "For images stored in Wasabi Bucket",
    url_key: "wasabi",
    marginBottom: "20px",
  },
  azure: {
    image: azimg,
    maxHeight: "",
    maxWidth: "10rem",
    title: "Azure Storage",
    description: "For images stored in Azure Blob Storage",
    url_key: "azure",
    marginBottom: "20px",
  },
  linode: {
    image: linimg,
    maxHeight: "",
    maxWidth: "4rem",
    title: "Linode Storage",
    description: "For images stored in Linode Buckets",
    url_key: "linode",
  },
  backblaze: {
    image: backimg,
    maxHeight: "",
    maxWidth: "10rem",
    title: "Backblaze B2",
    description: "For images stored in Backblaze B2",
    url_key: "backblaze",
    marginBottom: "26px",
  },
  cloudinary: {
    image: "https://www.gumlet.com/public/dashboard/img/cloudinary.png",
    maxHeight: "",
    maxWidth: "2.6rem",
    title: "Cloudinary",
    description: "To Migrate from Cloudinary",
    url_key: "cloudinary",
  },
  cloudflare: {
    image: cldimg,
    maxHeight: "",
    maxWidth: "10rem",
    title: "Cloudflare R2 Storage",
    description: "For images stored in R2 Bucket",
    url_key: "cloudflare",
  },
  imgix: {
    image: "https://www.gumlet.com/public/dashboard/img/imgix.svg",
    maxHeight: "3.5rem",
    maxWidth: "",
    title: "Imgix",
    description: "To Migrate from Imgix",
    url_key: "imgix",
    marginBottom: "20px",
    defaultSelected: {
      label: "Web Folder",
      value: "webfolder",
    },
    fields: [
      {
        name: "Source type",
        type: "select",
        placeholder: "Select",
        api_key: "option",
        options: [
          {
            label: "Web Folder",
            value: "webfolder",
          },
          {
            label: "Web Proxy",
            value: "proxy",
          },
          {
            label: "Amazon S3 Bucket",
            value: "aws",
          },
          {
            label: "DigitalOcean Spaces Bucket",
            value: "dostorage",
          },
          {
            label: "Wasabi Storage",
            value: "wasabi",
          },
          {
            label: "Google Cloud Storage",
            value: "gcs",
          },
          {
            label: "Azure Storage",
            value: "azure",
          },
          {
            label: "Linode Storage",
            value: "linode",
          },
          {
            label: "Backblaze Storage",
            value: "backblaze",
          },
        ],
        description:
          "Source type gives us information about which storage system you use for your video storage. We fetch master videos from the source you configure here.",
        tool_tip: "https://docs.gumlet.com/docs/configure-image-source",
      },
    ],
  },
};
