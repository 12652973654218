import React, { Fragment, useContext, useState } from "react";
import { Modal } from "react-responsive-modal";
import DataProvider from "context/DataContext";
import PricingModal from "components/PricingModal/PricingModal";

export default function Upgrade({ customClass, plan, from, onClick, btnText }) {
  const { setFeedbackModal } = useContext(DataProvider);

  const [open, setOpen] = useState(false);
  const onOpenModal = () => {
    setOpen(true);
    if (from === "nudge" && onClick) {
      onClick();
    }
  };
  const onCloseModal = () => setOpen(false);

  return (
    <Fragment>
      <button
        className={customClass ? customClass : "btn btn-dark"}
        onClick={onOpenModal}
        type="button"
      >
        <i className="tio-arrow-drop-up-circle-outlined me-1" />
        {btnText ? btnText : "Upgrade Plan"}
      </button>
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        showCloseIcon={false}
        classNames={{
          modal: "newPriceModal",
        }}
      >
        <PricingModal
          onCloseModal={onCloseModal}
          plan={plan}
          from={from}
          setFeedbackModal={setFeedbackModal}
        />
      </Modal>
    </Fragment>
  );
}
